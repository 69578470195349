import axios from "axios"

export const createFile = (file: any, tipo: string) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("tipo", tipo)
    
    axios
      .post("/api/Global/uploadFile", formData)
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}

export default createFile
