import axios from "axios"
import Catalogo from "../../interfaces/catalogo"

export const updateCatalogo = (id: number, formValues: Catalogo) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/QuickProtettoFile/${id}`, formValues)
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default updateCatalogo
