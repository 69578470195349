import { Stack, Theme, Toolbar, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import { FC } from "react"

interface FooterInterface {
  sx?: SxProps<Theme>
  // Add other props here
  // e.g., children?: React.ReactNode;
}

const Footer: FC<FooterInterface> = ({ sx }) => {
  return (
    <Toolbar
      variant="dense"
      sx={{
        borderTop: "1px solid var(--divider-color)",
        backgroundColor: "var(--base-foreground-color)",
        color: "var(--color)",
        ...sx
      }}
    >
      <Stack flexDirection="row" sx={{ width: "100%" }} alignItems="center">
        <Typography variant="body1" sx={{ flex: 1 }}>
          Release: 1.1.0
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginRight: "10px", color: "var(--color)" }}
        >
          &#169; {new Date().getFullYear()} Bludata Informatica S.r.l.
        </Typography>
        <img
          onClick={() => {
            window.open("https://www.bludata.com")
          }}
          style={{ height: "26px", cursor: "pointer", width: "auto" }}
          src="https://focus.bludata.com/logo_bludata_50.png"
          alt="Logo Bludata Informatica S.r.l."
        />
      </Stack>
    </Toolbar>
  )
}

export default Footer
