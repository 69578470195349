import { Stack } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { Box, Button, Card, Typography } from "@mui/material"
import { memo, useEffect, useState } from "react"
import { GridRowId, useGridApiRef } from "@mui/x-data-grid-premium"
import BludataDefaultMainHeader from "../../../UI/BludataDefaultMainHeader"
import { BluAlert, BluLoader } from "@bludata/components"
import UtenteMaster from "../../../interfaces/utenteMaster"
import fetchListaUtentiMaster from "../../../api/utenteMaster/fetchListaUtentiMaster"
import updateUtenteMaster from "../../../api/utenteMaster/updateUtenteMaster"
import createUtenteMaster from "../../../api/utenteMaster/createUtenteMaster"
import { customAlphabet } from "nanoid"
import deleteUtenteMaster from "../../../api/utenteMaster/deleteUtenteMaster"
import { toggleEnableTree } from "../../../store/root"
import isValidEmail from "../../../lib/isValidEmail"
import fetchGruppo from "../../../api/gruppi/fetchGruppo"
import Gruppo from "../../../interfaces/gruppo"
import setInviaResetPassword from "../../../api/utenteMaster/setInviaResetPassword"
import UsersDataGrid from "./components/UsersDataGrid"

export const ConfigurazioneUtenti = memo(() => {
  const numberAlphabet = "0123456789"
  const { loginData, editing } = useSelector((state: RootState) => state.root)
  const [userList, setUsers] = useState<{
    currentPage: number
    pageSize: number
    totalItems: number
    totalPages: number
    users: UtenteMaster[]
  }>()
  const dispatch = useDispatch()
  const [group, setGroup] = useState<Gruppo[]>([])
  const [loading, setLoading] = useState(false)
  const requiredFields = ["user", "nomeUtente", "eMail", "gruppo"]
  const [editingCell, setEditingCell] = useState<{
    id: GridRowId
    field: string
  }|null>(null)
  const [editedRow, setEditedRow] = useState<GridRowId[]>([])
  const gridApiRef = useGridApiRef()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const pageSize = 10
  const [page, setPage] = useState(0)
  const [deleteUser, setDeleteUser] = useState<{
    id: number
    field: string
    value: any
  }>()

  const [resetPasswordUser, setResetPasswordUser] = useState<{
    eMail: string
    user: string
  }>()

  const [errorDatagrid, setErrorDtagrid] = useState<string[]>([])

  const reloadUser = () => {
    if (loginData?.admin) {
      setLoading(true)
      fetchListaUtentiMaster(page + 1, pageSize)
        .then((res) => {
          setLoading(false)
          setUsers(res)
        })
        .catch((err) => {
          setError(err || "Internal Server Error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const reloadGroups = () => {
    if (loginData?.admin) {
      setLoading(true)
      fetchGruppo()
        .then((res) => {
          setLoading(false)
          setGroup(res)
        })
        .catch((err) => {
          setError(err || "Internal Server Error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const restart = () => {
    setLoading(false)
    setEditingCell(null)
    setEditedRow([])
    toggleEdit()
    reloadUser()
  }

  useEffect(() => {
    if (editing) reloadGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing])

  useEffect(() => {
    reloadUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const toggleEdit = () => {
    dispatch(toggleEnableTree())
  }

  const onSubmit = () => {
    setLoading(true)

    let newRows = [...editedRow]
    const handlePromiseError = (promise: any) => {
      return promise.catch((err: any) => ({ error: err }))
    }

    if (
      editingCell !== null &&
      gridApiRef.current !== undefined &&
      editedRow.length
    ) {
      gridApiRef.current.setCellMode(editingCell.id, editingCell.field, "edit")
      if (
        gridApiRef.current.getCellMode(editingCell.id, editingCell.field) ===
        "edit"
      ) {
        gridApiRef.current.commitCellChange(editingCell)

        const isIdPresent = newRows.includes(editingCell.id)
        if (!isIdPresent) {
          newRows = [...newRows, editingCell.id]
        }

        setEditedRow(newRows)

        gridApiRef.current.setCellMode(
          editingCell.id,
          editingCell.field,
          "view"
        )
        setEditingCell(null)
      }
    }

    let userPromises = editedRow.length
      ? newRows.map((id) => {
          const currentUser = userList!.users.find((user) => user.id === id)
          if (currentUser && currentUser.create) {
            return handlePromiseError(
              createUtenteMaster({
                id: undefined,
                descrizioneGruppo: currentUser.descrizioneGruppo,
                gruppo: currentUser.gruppo,
                user: currentUser.user,
                nomeUtente: currentUser.nomeUtente,
                eMail: currentUser.eMail,
                attivo: currentUser.attivo
              })
            )
          } else if (currentUser) {
            return handlePromiseError(
              updateUtenteMaster(Number(id), currentUser)
            )
          }
          return Promise.resolve()
        })
      : []

    Promise.all([...userPromises])
      .then(() => {
        if (editedRow.length === 0) return
        setSuccess(
          editedRow.length > 1
            ? "Utenti salvati con successo inviata con successo."
            : "Utente salvato con successo"
        )
      })
      .catch((err) => {
        setError(err || "Internal Server Error")
      })
      .finally(() => {
        setLoading(false)
        restart()
      })
  }

  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",
        backgroundColor: "var(--hovered-background-color)"
      }}
    >
      <BluAlert
        show={!!success}
        handleClose={() => {
          setSuccess("")
        }}
        text={success}
        type="success"
      />
      <BluAlert
        show={!!error}
        handleClose={() => {
          setError("")
        }}
        text={error}
        type="error"
      />
      <BluAlert
        show={!!resetPasswordUser}
        handleClose={() => {
          setResetPasswordUser(undefined)
        }}
        handleConfirm={() => {
          setInviaResetPassword(
            resetPasswordUser!.eMail,
            resetPasswordUser!.user
          )
            .then(() => {
              setSuccess("Mail di Reset Password inviata con successo.")
            })
            .catch((err) => {
              setError(err.response.data.response||"Errore nella fase di aggiornamento")
              setResetPasswordUser(undefined)
            })
            .finally(() => {
              setResetPasswordUser(undefined)
            })
        }}
        text={
          "Stai per inviare una mail al cliente per resettare la password. Sei sicuro di voler procedere?"
        }
        title="Attenzione"
        type="warning"
      />
      <BluAlert
        show={deleteUser !== undefined}
        handleClose={() => {
          setDeleteUser(undefined)
        }}
        handleConfirm={() => {
          if (
            userList?.users.filter((user) => user.id === deleteUser?.id)[0]
              .create
          ) {
            setEditedRow((x) => {
              return [...x].filter((id) => id !== deleteUser?.id)
            })
            setUsers((prevRows) => {
              if (!prevRows) return
              return {
                ...prevRows,
                users: prevRows!.users.filter(
                  (deletedItem) => deletedItem.id !== deleteUser?.id
                )
              }
            })
            setDeleteUser(undefined)
            setEditingCell(null)
          } else {
            setLoading(true)
            deleteUtenteMaster(deleteUser!.id)
              .then(() => {
                setSuccess("Utente eliminato con successo.")
              })
              .catch((err) => {
                setError(err.response.data.response||"Errore durante l'eliminazione")
                setLoading(false)
              })
              .finally(() => {
                setLoading(false)
                setEditedRow((x) => {
                  return [...x].filter(
                    (deletedItem) => deletedItem !== deleteUser?.id
                  )
                })
                setUsers((prevRows) => {
                  if (!prevRows) return
                  return {
                    ...prevRows,
                    users: prevRows!.users.filter(
                      (deletedItem) => deletedItem.id !== deleteUser?.id
                    )
                  }
                })
                setDeleteUser(undefined)
              })
          }
        }}
        text={
          "Sei sicuro di voler eliminare questo utente da Catalogo Bludata?"
        }
        type="warning"
      />
      <Stack flexDirection="column" flex={1} sx={{ height: "100%" }}>
        <BludataDefaultMainHeader
          showReload={!editing}
          text={"Configurazione / Utenti"}
          onRefreshTap={reloadUser}
        />
        <Card variant="outlined" sx={{ height: "100%", p: 1, m: 0.5, mt: 0 }}>
          <Stack direction="column" spacing={1} sx={{ height: "100%" }}>
            <BluLoader open={loading} />
            <Typography>
              In questa sezione è possibile configurare gli utenti. I campi Nome
              utente, Gruppo, Email e User sono obbligatori.
            </Typography>
            {loginData?.admin && (
              <>
                <UsersDataGrid
                  gridApiRef={gridApiRef}
                  groupList={group}
                  userList={
                    userList || {
                      currentPage: 0,
                      pageSize: 0,
                      totalItems: 0,
                      totalPages: 0,
                      users: []
                    }
                  }
                  page={page}
                  onEditingCell={(id: GridRowId, field: string) => {
                    setEditingCell({ id, field })
                  }}
                  onEdit={(id: number, field: string, value: any) => {
                    setUsers((prevRows) => {
                      if (!prevRows) return
                      return {
                        ...prevRows,
                        users: prevRows.users.map((row) => {
                          if (row.id === id) {
                            setErrorDtagrid((prevErrors) => {
                              const isFieldPresent = prevErrors.includes(field)

                              if (field === "eMail") {
                                if (!isValidEmail(value) && !isFieldPresent) {
                                  return [...prevErrors, field]
                                } else if (
                                  isValidEmail(value) &&
                                  isFieldPresent
                                ) {
                                  return prevErrors.filter(
                                    (error) => error !== field
                                  )
                                }
                              } else {
                                if (value.length > 0 && isFieldPresent) {
                                  return prevErrors.filter(
                                    (error) => error !== field
                                  )
                                } else if (
                                  value.length === 0 &&
                                  !isFieldPresent
                                ) {
                                  return [...prevErrors, field]
                                }
                              }

                              return prevErrors
                            })

                            setEditedRow((prev) => {
                              const isIdPresent = prev.includes(id)
                              if (!isIdPresent) {
                                return [...prev, id]
                              }
                              return prev
                            })
                            return { ...row, [field]: value }
                          }
                          return row
                        })
                      }
                    })
                  }}
                  onDelete={(id: number, field: string, value: any) => {
                    setDeleteUser({ id, field, value })
                  }}
                  onPageChange={(currentPage: number) => {
                    setPage(currentPage)
                  }}
                  onResetPasswordUser={(eMail: string, user: string) => {
                    setResetPasswordUser({ eMail, user })
                  }}
                />
              </>
            )}
            {editing ? (
              <Stack 
              direction={"row"}
              spacing={0.5}
              justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    const id = Number(customAlphabet(numberAlphabet, 10)())

                    setUsers((prev: any) => {
                      return {
                        ...prev,
                        users: [
                          ...prev.users,
                          {
                            id: id,
                            gruppo: "",
                            user: "",
                            password: "",
                            isAdmin: false,
                            nomeUtente: "",
                            eMail: "",
                            attivo: false,
                            token: "",
                            scadenzaToken: "",
                            create: true
                          }
                        ]
                      }
                    })
                    setErrorDtagrid(requiredFields)
                    setTimeout(() => {
                      gridApiRef.current.selectRow(id)
                    }, 100)
                  }}
                >
                  Nuovo utente
                </Button>
                <Box width={20}></Box>
                <Button
                  onClick={() => {
                    restart()
                  }}
                  variant="outlined"
                >
                  Annulla
                </Button>
                <Button
                  onClick={
                    !(errorDatagrid.length === 0)
                      ? () => {
                          setError(
                            `${
                              errorDatagrid.length !== 0
                                ? "I campi Nome utente, Gruppo, Email e User sono obbligatori e devono essere validi."
                                : ""
                            }`
                          )
                        }
                      : onSubmit
                  }
                >
                  Salva
                </Button>
              </Stack>
            ) : (
              <Stack direction={"row"} justifyContent={"end"} spacing={0.5}>
                <Button onClick={toggleEdit} variant="outlined">
                  Modifica
                </Button>
              </Stack>
            )}
          </Stack>
        </Card>
      </Stack>
    </Box>
  )
})
