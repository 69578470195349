import {
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip
} from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium"
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder"
import DeleteIcon from "@mui/icons-material/Delete"
import Gruppo from "../../../interfaces/gruppo"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import { keyframes } from "@emotion/react"
import DataGridTextField from "../../../UI/DataGridTextField"

const columnsHandler = (
  edit: boolean,
  groupList: Gruppo[],
  onValueChange: (id: any, field: string, value: any) => void,
  onDelete: (id: any, field: string, value: any) => void,
  onUploadClick: (file: any, tipo: string, id: Number) => void,
  onFileBig: () => void
) => {
  const groupOptions = groupList.map((group) => {
    return { gruppo: group.codiceGruppo, descrizione: group.descrizione }
  })
  const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

  return [
    {
      field: "id",
      headerName: "",
      width: 70,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      hide: true
    },
    {
      field: "descrizione",
      headerName: "Descrizione",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <DataGridTextField
            value={params.value || ""}
            onChange={(newValue) =>
              onValueChange(params.id, params.field, newValue)
            }
            disabled={!edit}
          />
        )
      }
    },
    {
      field: "gruppi",
      headerName: "Gruppi",
      flex: 1,
      renderCell: (params) => {
        const handleInputChange = (event: SelectChangeEvent<string[]>) => {
          const values = event.target.value as string[]
          onValueChange(
            params.id,
            params.field,
            values.map((groupId) =>
              groupOptions.find((group) => group.gruppo === groupId)
            )
          )
        }

        return (
          <Tooltip
            title={params.row["gruppi"]
              .map((group: Gruppo) => {
                return `${group.descrizione}`
              })
              .join(" , ")}
          >
            {groupOptions.length ? (
              <Select
                multiple
                value={params.value.map((group: any) => group.gruppo) || []}
                style={{ width: "100%" }}
                sx={{
                  "&.MuiInputBase-root": {
                    fontWeight: "400!important",
                    border: "none!important"
                  },
                  "&.Mui-focused:after": { border: "none!important" },
                  "&.MuiInput-underline:after": { border: "none!important" }
                }}
                disabled={!edit}
                onChange={handleInputChange}
                renderValue={(selected) => {
                  return selected
                    .map(
                      (value: any) =>
                        groupOptions.find((option) => option.gruppo === value)
                          ?.descrizione
                    )
                    .join(", ")
                }}
              >
                {groupOptions.map((option) => (
                  <MenuItem key={option.gruppo} value={option.gruppo}>
                    {option.descrizione}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    fontWeight: "400!important"
                  }
                }}
                fullWidth
                value={params.row["gruppi"]
                  .map((gruppo: any) => {
                    return gruppo.descrizione
                  })
                  .join(",")}
                disabled
              />
            )}
          </Tooltip>
        )
      }
    },
    {
      field: "tipo",
      headerName: "Tipo",
      width: 110,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <DataGridTextField
            value={params.value || ""}
            onChange={(newValue) =>
              onValueChange(params.id, params.field, newValue)
            }
            disabled={!edit}
          />
        )
      }
    },
    {
      field: "file",
      headerName: "File",
      width: 45,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <input
              accept=".zip"
              type="file"
              id={`inputId-${params.id}`}
              hidden
              onChange={(e: any) => {
                if (e.target.files.length > 0) {
                  const file = e.target.files[0]

                  if (file.size > 20 * 1024 * 1024) {
                    onFileBig()
                    return
                  }
                  document.getElementById(`file-${params.id}`)!.style.display =
                    "none"
                  document.getElementById(
                    `loading-${params.id}`
                  )!.style.display = "block"

                  const fileReader = new FileReader()
                  try {
                    fileReader.onload = (event: any) => {
                      const fileData = new File(
                        [event.target.result],
                        file.name,
                        { type: file.type }
                      )

                      document.getElementById(
                        `file-${params.id}`
                      )!.style.display = "block"
                      document.getElementById(
                        `loading-${params.id}`
                      )!.style.display = "none"

                      onUploadClick(
                        fileData,
                        params.row["tipo"],
                        Number(params.id)
                      )
                    }
                  } catch (error) {
                    document.getElementById(
                      `file-${params.id}`
                    )!.style.display = "block"
                    document.getElementById(
                      `loading-${params.id}`
                    )!.style.display = "none"
                  }

                  fileReader.readAsArrayBuffer(file)
                }
              }}
            />
            <label htmlFor={`inputId-${params.id}`} id={`file-${params.id}`}>
              <Tooltip
                title={
                  params.row["tipo"].length <= 0
                    ? `Inserire un tipo prima di caricare un file`
                    : params.row["file"].length > 0
                    ? `Aggiorna ${params.row["file"]}`
                    : `Carica file`
                }
              >
                <IconButton disabled={!edit} color="error" component="span">
                  <CreateNewFolderIcon
                    color={
                      !edit || params.row["tipo"].length <= 0
                        ? "disabled"
                        : "primary"
                    }
                  />
                </IconButton>
              </Tooltip>
            </label>
            <AutorenewIcon
              id={`loading-${params.id}`}
              sx={{
                display: "none",
                animation: `${rotate} 2s linear infinite`
              }}
              color="primary"
            />
          </>
        )
      }
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      editable: true,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const handleDelete = (event: any) => {
          onDelete(params.id, params.field, event.target.value)
        }
        return (
          <IconButton disabled={!edit} color="error" onClick={handleDelete}>
            <Tooltip title="Cancella utente">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )
      }
    }
  ] as GridColDef[]
}
export default columnsHandler
