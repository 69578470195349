import axios from "axios"

export const deleteCatalogo = (id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/QuickProtettoFile/${id}`)
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default deleteCatalogo
