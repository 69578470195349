import axios from "axios"
import Utente from "../../interfaces/utente"

export const createUtente = (formValues: Utente) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/UtenteOttico`, formValues)
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default createUtente
