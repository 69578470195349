const controlloPivaCodFisc = function (
  value: string,
  canBeCodFisc: boolean,
  onlyCf: boolean
) {
  var s = 0,
    charAtZero = "0".charCodeAt(0),
    calc = (i: any) => value.charCodeAt(i) - charAtZero

  if (
    value.match(
      /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$|^[0-9]{11}$/
    )
  ) {
    if (canBeCodFisc && value.match(/[a-zA-Z]/)) {
      var cf = value.toUpperCase(),
        map = [
          1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17, 19, 21,
          2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23
        ]
      s = 0
      for (var i = 0; i < 15; i++) {
        var c = cf.charCodeAt(i)
        if (c < 65) c = c - 48
        else c = c - 55
        if (i % 2 === 0) s += map[c]
        else s += c < 10 ? c : c - 10
      }
      var atteso = String.fromCharCode(65 + (s % 26))
      if (atteso !== cf.charAt(15)) return "Il codice fiscale non è valido"
      return true
    }

    if (canBeCodFisc && onlyCf) return "Il codice fiscale non è valido"

    for (i = 0; i <= 9; i += 2) s += calc(i)
    for (i = 1; i <= 9; i += 2) {
      c = 2 * calc(i)
      if (c > 9) c -= 9
      s += c
    }
    if ((10 - (s % 10)) % 10 !== calc(10)) return "La Partita IVA non è valida"
    return !0
  } else {
    return "Formato non valido"
  }
}

export default controlloPivaCodFisc
