import axios from "axios"

export const setInviaResetPassword = (userEmail: string, userName: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/UtenteMaster/inviaMailResetPassword`, {
        userEmail,
        userName
      })
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default setInviaResetPassword
