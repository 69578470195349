import axios from "axios"
import UtenteMaster from "../../interfaces/utenteMaster"

export const fetchListaUtentiMaster = (page: number, pageSize: number) => {
  return new Promise<{
    currentPage:number,
    pageSize:number,
    totalItems:number,
    totalPages:number,
    users: UtenteMaster[]
  }>((resolve, reject) => {
    axios
      .get("/api/UtenteMaster", {
        params: {
          page: page,
          pageSize: pageSize
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default fetchListaUtentiMaster
