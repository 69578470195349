import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import Utente from "../../interfaces/utente"

export const fetchDefaultUtente = () => {
  return new Promise<Utente>((resolve, reject) => {
    axios
      .get(`/api/UtenteOttico/GetDefaulUtente`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}

export const useDefaultUtente = (key: string = "") => {
  return useQuery({
    queryFn: async () => await fetchDefaultUtente(),
    queryKey: ["user", key],
    retry: 2,
    retryDelay: 180000
  })
}

export default fetchDefaultUtente
