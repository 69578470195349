import { ReactNode } from "react"
import {
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Typography
} from "@mui/material"
import { memo, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import { setCurrentTreeSelection } from "../../store/root"

export const TreeItem = memo(
  ({
    descrizione,
    tipo,
    icona,
    children
  }: {
    descrizione: string
    tipo: string
    icona: ReactNode
    children?: ReactNode
  }) => {
    const dispatch = useDispatch()
    const { menuOpened, currentTreeSelection, editing } = useSelector(
      (state: RootState) => state.root
    )
    const Icon = useMemo(() => {
      return (
        <ListItemIcon
          sx={{
            minWidth: "fit-content",
            mr: menuOpened ? "15px" : "0px",
            color: "var(--base-color)",
            ml: menuOpened ? "0px" : "8px"
          }}
        >
          {icona}
        </ListItemIcon>
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuOpened])

    const Text = useMemo(() => {
      if (!menuOpened) return <></>
      return (
        <Typography
          variant="subtitle1"
          sx={{
            mr: menuOpened ? "15px" : "0px",
            color: "var(--base-color)",
            fontWeight: "normal !important",
            fontSize: "18px !important",
            lineHeight: "20px !important"
          }}
        >
          {descrizione}
        </Typography>
      )
    }, [menuOpened, descrizione])

    return (
      <Tooltip title={menuOpened ? null : descrizione}>
        <>
          <ListItemButton
            disabled={editing}
            selected={currentTreeSelection?.id === tipo}
            onClick={() => {
              dispatch(
                setCurrentTreeSelection({ label: descrizione, id: tipo })
              )
            }}
            style={{ padding: "7px 15px" }}
          >
            {Icon}
            {Text}
          </ListItemButton>
          {children && <div style={{ paddingLeft: "15px" }}>{children}</div>}
        </>
      </Tooltip>
    )
  }
)
