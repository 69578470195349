import axios from "axios"
import UtenteMaster from "../../interfaces/utenteMaster"

export const updateUtenteMaster = (id: number, formValues: UtenteMaster) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/UtenteMaster/${id}`, formValues)
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default updateUtenteMaster
