import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getCookie } from "../lib/cookie"
import UtenteMaster from "../interfaces/utenteMaster"
import jwt_decode from "jwt-decode"
import token from "../interfaces/token"
import leaf from "../interfaces/leaf"

const darkModeCookie: any = getCookie("darkMode")

export interface RootSliceState {
  accessToken: string
  sessionExpired: boolean
  refreshToken: string
  darkMode: "light" | "dark"
  isAuthenticated: boolean
  menuOpened: boolean
  currentTreeSelection?: {label:string,id:string}
  loginData?: {
    userName: string
    admin: boolean
    gruppo: string
    id: number
  }
  user: UtenteMaster | null
  editing: boolean
  treeItemList:leaf[]
}

let loginData = undefined
if (getCookie("AdminToken"))
  loginData = {
    userName: getCookie("AdminNomeUtente")!,
    admin:
      jwt_decode<token>(getCookie("AdminToken") || "").amministratore ===
      "True",
    gruppo: getCookie("AdminGruppo")!,
    id: Number(getCookie("AdminId"))
  }

const initialState: RootSliceState = {
  accessToken: getCookie("AdminToken") ?? "",
  refreshToken: getCookie("AdminRefreshToken") ?? "",
  darkMode: darkModeCookie ?? "light",
  sessionExpired: false,
  isAuthenticated: false,
  menuOpened: true,
  user: null,
  loginData: loginData,
  editing: false,
  treeItemList:[]
}

const RootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setAccessToken(state: RootSliceState, action: PayloadAction<string>) {
      state.accessToken = action.payload
    },
    setUser(state: RootSliceState, action: PayloadAction<UtenteMaster>) {
      state.user = action.payload
    },
    toggleEnableTree(state: RootSliceState) {
      state.editing = !state.editing
    },
    toggleMenuOpened(state: RootSliceState) {
      state.menuOpened = !state.menuOpened
    },
    setCurrentTreeSelection(
      state: RootSliceState,
      action: PayloadAction<{label:string,id:string}>
    ) {
      state.currentTreeSelection = action.payload
    },
    setTreeItemList(
      state: RootSliceState,
      action: PayloadAction<leaf[]>
    ) {
      state.treeItemList = action.payload
    },
    setRefreshToken(state: RootSliceState, action: PayloadAction<string>) {
      state.refreshToken = action.payload
    },
    setAuthenticated(state: RootSliceState, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
    setLoginData(
      state: RootSliceState,
      action: PayloadAction<{
        userName: string
        admin: boolean
        gruppo: string
        id: number
      }>
    ) {
      state.loginData = action.payload
    },
    toggleDarkMode(state: RootSliceState) {
      state.darkMode = state.darkMode === "light" ? "dark" : "light"
    },
    setSessionExpired(state: RootSliceState, action: PayloadAction<boolean>) {
      state.sessionExpired = action.payload
    }
  }
})

export const {
  setAccessToken,
  setRefreshToken,
  setLoginData,
  toggleMenuOpened,
  toggleDarkMode,
  setSessionExpired,
  setAuthenticated,
  setCurrentTreeSelection,
  setUser,
  toggleEnableTree,
  setTreeItemList
} = RootSlice.actions

export default RootSlice.reducer
