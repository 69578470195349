import { IconButton, InputAdornment, TextField } from "@mui/material"
import { FC } from "react"
import { Controller } from "react-hook-form"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import { GridCloseIcon } from "@mui/x-data-grid-premium"

export const FormInputText: FC<{
  name: string
  label: string
  control: any
  errors?: any
  sx?: any
  toggleEdit?: boolean
  pattern?: string
  onBlurProp?: (e: any) => void
  onChangeProp?: (e: any) => void
  maximize?: boolean
  deletetable?: boolean
  onClickAdornment?: (arg: "maximize" | "delete") => void
  maxLength?: number
  number?: boolean
}> = (props) => {
  const {
    name,
    control,
    label,
    sx,
    toggleEdit,
    onBlurProp,
    onChangeProp,
    onClickAdornment,
    maximize,
    deletetable,
    errors,
    maxLength
  } = props
  return (
    <Controller
      name={name}
      control={control}
      key={name}
      defaultValue=""
      render={({ field: { value, onChange } }) => {
        return (
          <TextField
            type={"text"}
            focused={!!errors ? Boolean(errors[name]) : undefined}
            error={!!errors ? Boolean(errors[name]) : undefined}
            value={value}
            onBlur={!!onBlurProp ? onBlurProp : undefined}
            onChange={!!onChangeProp ? onChangeProp : onChange}
            key={name}
            label={label}
            name={name}
            size="small"
            fullWidth
            inputProps={{
              maxLength: !!maxLength ? maxLength : undefined,
            }}
            disabled={!!toggleEdit ? toggleEdit : false}
            InputProps={{
              endAdornment: (
                <>
                  {!!maximize ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle size"
                        onClick={() => {
                          if (onClickAdornment) onClickAdornment("maximize")
                        }}
                        edge="end"
                      >
                        {<OpenInFullIcon />}
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  )}
                  {!!deletetable && value && !toggleEdit ? (
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        sx={{
                          p: 0,
                          top: 4,
                          right: 4,
                          position: "absolute"
                        }}
                        size="small"
                        onClick={() => {
                          //!gestire l'elimina
                          if (onClickAdornment) onClickAdornment("delete")
                        }}
                      >
                        <GridCloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  )}
                </>
              )
            }}
            sx={{ ...sx }}
          />
        )
      }}
    />
  )
}
