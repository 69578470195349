import { FC, ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Box, Button, Stack } from "@mui/material"
import { FormInputText } from "../../UI/UseFormComponents/FormInputText"
import axios from "axios"
import { useDefaultUtente } from "../../api/utenteOttico/fetchDefaultUtente"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import updateUtente from "../../api/utenteOttico/updateUtente"
import createUtente from "../../api/utenteOttico/createUtente"
import { yupResolver } from "@hookform/resolvers/yup"
import { schema } from "./schema"
import { BluAlert, BluDialog, BluTitle } from "@bludata/components"

const CentroOtticoForm: FC<any> = (props): JSX.Element => {
  const { isEdit, id } = props

  const { currentTreeSelection } = useSelector((state: RootState) => state.root)

  const { data: defaultValues } = useDefaultUtente()
  const [error, setError] = useState<string|ReactElement>("")

  const methods = useForm<any>({
    defaultValues: defaultValues,
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema)
  })

  const {
    control,
    setValue,
    formState: { errors },
    reset
  } = methods

  useEffect(() => {
    if (isEdit === "view" || isEdit === "edit") {
      axios
        .get(`/api/UtenteOttico/${id}`)
        .then((res: any) => {
          reset(res.data)
        })
        .catch((err) => {
          setError(err || "Internal Server Error")
        })
    } else {
      reset(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit])

  const onSubmit = (data: any) => {
    const newFormValue = data
    if (isEdit === "edit") {
      updateUtente(id, { ...newFormValue })
        .catch((err) => {
          setError(err || "Internal Server Error")
        })
        .finally(() => {
          reset(defaultValues)
          props.close()
        })
    } else {
      createUtente({
        ...newFormValue,
        tipo: currentTreeSelection?.id
      })
        .catch((err) => {
          setError(err || "Internal Server Error")
        })
        .finally(() => {
          reset(defaultValues)
          props.close()
        })
    }
  }

  const onFormError = (err: any) => {

    let errList = []

    for (const key in err) {
      if (Object.prototype.hasOwnProperty.call(err, key)) {
        const element = err[key];
        errList.push(element.message)
      }
    }

    setError(<ul>{errList.map(m=> {return <li>{m}</li>})}</ul>)
  }

  return (
    <>
      <BluAlert
        type="error"
        show={!!error}
        handleClose={() => {
          setError("")
        }}
        text={error}
      />
      <BluDialog
        open={!!isEdit}
        draggable
        maxWidth="sm"
        zIndex={9999}
        dialogTitle={"Centro ottico"}
        PaperProps={
          {
            component: "form",
            onSubmit: methods.handleSubmit(onSubmit, onFormError)
          } as any
        }
        dialogContent={
          <Stack spacing={1} direction={"column"}>
            <BluTitle text="Generali" />
            <FormInputText
              name={"ragioneSociale"}
              control={control}
              errors={errors}
              toggleEdit={isEdit === "view"}
              label="Ragione sociale"
              deletetable
              maxLength={50}
              onClickAdornment={(arg) => {
                if (arg === "delete") {
                  setValue("ragioneSociale", "")
                }
              }}
            />
            <FormInputText
              name={"indirizzo"}
              control={control}
              errors={errors}
              maxLength={50}
              toggleEdit={isEdit === "view"}
              label="Indirizzo"
              deletetable
              onClickAdornment={(arg) => {
                if (arg === "delete") {
                  setValue("indirizzo", "")
                }
              }}
            />
            <FormInputText
              name={"cap"}
              control={control}
              errors={errors}
              maxLength={5}
              number
              toggleEdit={isEdit === "view"}
              label="CAP"
              deletetable
              onClickAdornment={(arg) => {
                if (arg === "delete") {
                  setValue("cap", "")
                }
              }}
            />
            <FormInputText
              name={"citta"}
              control={control}
              errors={errors}
              maxLength={30}
              toggleEdit={isEdit === "view"}
              label="Città"
              deletetable
              onClickAdornment={(arg) => {
                if (arg === "delete") {
                  setValue("citta", "")
                }
              }}
            />
            <FormInputText
              name={"provincia"}
              control={control}
              errors={errors}
              maxLength={2}
              toggleEdit={isEdit === "view"}
              label="Provincia"
              deletetable
              onClickAdornment={(arg) => {
                if (arg === "delete") {
                  setValue("provincia", "")
                }
              }}
            />
            <FormInputText
              name={"partitaIVA"}
              control={control}
              errors={errors}
              toggleEdit={isEdit === "view"}
              label="Partita IVA"
              maxLength={15}
              deletetable
              onClickAdornment={(arg) => {
                if (arg === "delete") {
                  setValue("partitaIVA", "")
                }
              }}
            />
            <Box sx={{ height: "30px" }} />
            <BluTitle text="Accesso" />
            <FormInputText
              name={"userId"}
              control={control}
              errors={errors}
              maxLength={10}
              toggleEdit={isEdit === "view"}
              label="Codice"
              deletetable
              onClickAdornment={(arg) => {
                if (arg === "delete") {
                  setValue("userId", "")
                }
              }}
            />
            <FormInputText
              name={"password"}
              control={control}
              errors={errors}
              maxLength={10}
              toggleEdit={isEdit === "view"}
              label="Password"
              deletetable
              onClickAdornment={(arg) => {
                if (arg === "delete") {
                  setValue("password", "")
                }
              }}
            />
          </Stack>
        }
        dialogActions={
          <>
            {!!isEdit && (
              <>
                <Button
                  onClick={() => {
                    reset(defaultValues)
                    props.close()
                  }}
                  variant="outlined"
                >
                  {isEdit === "view" ? "Chiudi" : "Annulla"}
                </Button>
                {isEdit !== "view" && (
                  <>
                    <Button type="submit">Salva</Button>
                  </>
                )}
              </>
            )}
          </>
        }
      />
    </>
  )
}

export default CentroOtticoForm
