import {
  Button,
  Stack,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material"
import { memo, useEffect, useState } from "react"
import { setUser, toggleDarkMode } from "../../store/root"
import LogoutIcon from "@mui/icons-material/Logout"
import DarkModeTwoToneIcon from "@mui/icons-material/DarkModeTwoTone"
import LightModeTwoToneIcon from "@mui/icons-material/LightModeTwoTone"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import { eraseCookie, setCookie } from "../../lib/cookie"
import { BluAlert, BluTitle } from "@bludata/components"
import UserDialog from "./userDialog/UserDialog"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import fetchUtenteMaster from "../../api/utenteMaster/fetchUtenteMaster"

const Header = () => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [error, setError] = useState("")
  const [logout, setLogout] = useState(false)

  const { darkMode, loginData, user } = useSelector(
    (state: RootState) => state.root
  )

  const reload = () => {
    if (loginData)
      fetchUtenteMaster(loginData!.id)
        .then((res) => {
          dispatch(setUser(res))
        })
        .catch((err) => {
          setError(err.response.data.response|| 'Internal server error')
        })
  }

  useEffect(() => {
    reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <BluAlert
        show={!!error}
        handleClose={() => {
          setError("")
        }}
        text={error}
        type="error"
      />
      <BluAlert
        show={logout}
        handleClose={() => {
          setLogout((x) => !x)
        }}
        handleConfirm={() => {
          eraseCookie("AdminToken")
          eraseCookie("AdminNomeUtente")
          eraseCookie("AdminGruppo")
          eraseCookie("AdminId")
          window.location.reload()
        }}
        text={
          "Sei sicuro di voler uscire da Catalogo Bludata?\nTutte le modifiche non salvate andranno perse."
        }
        type="warning"
      />
      <Toolbar
        sx={{
          backgroundColor: "var(--base-foreground-color)",
          backgroundImage: "none",
          zIndex: 1,
          boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          height: "64px"
        }}
      >
        {/**
 * 
 * 
 *         <IconButton
          size="large"
          edge="start"
          color="primary"
          aria-label="menu"
          sx={{ mr: 1 }}
          onClick={() => {
            dispatch(toggleMenuOpened())
          }}
        >
          <MenuIcon />
        </IconButton>
 * 
 * 
 */}
        <Typography
          color="primary"
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, pl: 0 }}
        >
          Catalogo Bludata
        </Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          onClick={() => {
            setShow(true)
          }}
          sx={{
            mr: "35px",
            p: 1,
            m: 1,
            cursor: "pointer",
            borderRadius: "5px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)"
            }
          }}
        >
          {loginData && (
            <>
              <AccountCircleIcon
                style={{
                  color: "var(--base-color)",
                  height: "50px",
                  width: "50px"
                }}
              />
              <Stack flexDirection="column" sx={{ ml: "10px" }}>
                <BluTitle
                  sx={{
                    color: "var(--color) !important"
                  }}
                  text="Benvenuto/a,"
                />
                <BluTitle text={!!user ? user!.nomeUtente : ""} />
              </Stack>
              {!!user && (
                <UserDialog
                  show={show}
                  onClose={() => {
                    setShow(false)
                    reload()
                  }}
                  user={user}
                />
              )}
            </>
          )}
        </Stack>
        <Tooltip title="Abilita / Disabilita modalità notturna">
          <Button
            onClick={() => {
              dispatch(toggleDarkMode())
              setCookie("darkMode", darkMode === "light" ? "dark" : "light")
            }}
            sx={{
              width: "32px",
              height: "32px",
              pl: "0px",
              pr: "0px",
              minWidth: "34px",
              marginRight: "5px"
            }}
            color="primary"
            variant="outlined"
          >
            {darkMode ? (
              <LightModeTwoToneIcon sx={{ paddingLeft: "4px" }} />
            ) : (
              <DarkModeTwoToneIcon sx={{ paddingLeft: "4px" }} />
            )}
          </Button>
        </Tooltip>
        <Tooltip title="Esci">
          <Button
            color="primary"
            onClick={() => {
              setLogout((x) => !x)
            }}
            variant="outlined"
            sx={{
              width: "32px",
              height: "32px",
              pl: "0px",
              pr: "0px",
              minWidth: "32px"
            }}
          >
            <LogoutIcon sx={{ paddingLeft: "4px" }} />
          </Button>
        </Tooltip>
      </Toolbar>
    </>
  )
}

export default memo(Header)
