import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"
import { IconButton, InputAdornment, SxProps, TextField } from "@mui/material"
import { ChangeEvent, memo } from "react"

interface Props {
  ricerca: string
  placeholder?: string
  label?: string
  sx?: SxProps
  disable?: boolean
  onRicercaChange: (arg: string) => void
}

function BludataSearchField(props: Props) {
  const { ricerca, placeholder, label, sx, onRicercaChange, disable } = props

  return (
    <>
      <input type="text" style={{ display: "none" }} />
      <TextField
        label={label}
        disabled={disable}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: ricerca ? (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  onRicercaChange("")
                }}
                edge="end"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          )
        }}
        placeholder={placeholder}
        value={ricerca}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onRicercaChange(event.target.value || "")
        }}
        sx={sx}
      />
    </>
  )
}

export default memo(BludataSearchField)
