import axios from "axios";

export const setConfermaResetPassword = (newPassword: string, token: string) => {
  return new Promise((resolve, reject) => {
    const md5 = require("md5");
    
    const instance = axios.create({
      baseURL: "https://admin-catalogo-api.azurewebsites.net"
    });

    instance.post("/api/UtenteMaster/confermaResetPassword", {
      newPassword: md5(newPassword),
      token
    })
    .then(() => {
      resolve(true);
    })
    .catch((error) => {
      reject(error.response.data.response);
    });
  });
}

export default setConfermaResetPassword;
