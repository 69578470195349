import {
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium"
import LockResetIcon from "@mui/icons-material/LockReset"
import DeleteIcon from "@mui/icons-material/Delete"
import Gruppo from "../../../interfaces/gruppo"
import DataGridTextField from "../../../UI/DataGridTextField"

const columnsHandler = (
  edit: boolean,
  groupList: Gruppo[],
  onValueChange: (id: any, field: string, value: any) => void,
  onDelete: (id: any, field: string, value: any) => void,
  onResetPassword: (userMail: string, userEmail: string) => void
) => {
  const groupOptions = groupList.map((group) => {
    return { id: group.codiceGruppo, label: group.descrizione }
  })

  return [
    {
      field: "id",
      headerName: "",
      width: 70,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      hide: true
    },
    {
      field: "nomeUtente",
      headerName: "Nome utente",
      width:200,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <DataGridTextField
            value={params.value || ""}
            onChange={(newValue) =>
              onValueChange(params.id, params.field, newValue)
            }
            disabled={!edit}
          />
        )
      }
    },
    {
      field: "gruppo",
      headerName: "Gruppo",
      width: 250,
      renderCell: (params) => {
        const handleInputChange = (event: any) => {
          const selectedGroupId = event.target.value
          const selectedGroup = groupList.find(
            (group) => group.codiceGruppo === selectedGroupId
          )
          const selectedGroupLabel = selectedGroup
            ? selectedGroup.descrizione
            : ""
          onValueChange(params.id, "gruppo", selectedGroupId)
          onValueChange(params.id, "descrizioneGruppo", selectedGroupLabel)
        }
        return (
          <>
            {groupOptions.length ? (
              <Select
                defaultValue=""
                style={{ width: "100%" }}
                value={params.value}
                sx={{
                  "&.MuiInputBase-root": {
                    fontWeight: "400!important",
                    border: "none!important"
                  },
                  "&.Mui-focused:after": { border: "none!important" },
                  "&.MuiInput-underline:after": { border: "none!important" }
                }}
                disabled={!edit}
                onChange={handleInputChange}
              >
                {groupOptions.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  )
                })}
              </Select>
            ) : (
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    fontWeight: "400!important"
                  }
                }}
                value={params.row["descrizioneGruppo"]}
                disabled
              />
            )}
          </>
        )
      }
    },
    {
      field: "eMail",
      headerName: "Email",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <DataGridTextField
            value={params.value || ""}
            onChange={(newValue) =>
              onValueChange(params.id, params.field, newValue)
            }
            disabled={!edit}
          />
        )
      }
    },
    {
      field: "attivo",
      headerName: "Attivo",
      width: 70,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const handleInputChange = (event: any, value: any) => {
          onValueChange(params.id, params.field, value)
        }
        return (
          <Checkbox
            disabled={!edit}
            checked={params.value}
            onChange={handleInputChange}
          />
        )
      }
    },
    {
      field: "user",
      headerName: "User",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <DataGridTextField
            value={params.value || ""}
            onChange={(newValue) =>
              onValueChange(params.id, params.field, newValue)
            }
            disabled={!edit}
          />
        )
      }
    },
    {
      field: "password",
      headerName: "",
      width: 45,
      editable: true,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton
            disabled={!edit}
            color="primary"
            onClick={() => {
              onResetPassword(params.row["eMail"], params.row["user"])
            }}
          >
            <Tooltip title="Reimposta password utente">
              <LockResetIcon />
            </Tooltip>
          </IconButton>
        )
      }
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      editable: true,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const handleDelete = (event: any) => {
          onDelete(params.id, params.field, event.target.value)
        }
        return (
          <IconButton disabled={!edit} color="error" onClick={handleDelete}>
            <Tooltip title="Cancella utente">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )
      }
    }
  ] as GridColDef[]
}
export default columnsHandler
