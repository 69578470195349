import axios from "axios"
import Gruppo from "../../interfaces/gruppo"

export const updateGruppo = (id: number, formValues: Gruppo) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/Gruppo/${id}`, formValues)
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default updateGruppo
