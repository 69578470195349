import { FC, memo } from "react";
import { SxProps, Theme, Typography } from "@mui/material";
import classes from "./BludataBluTitle.module.css";

interface BludataBluTitleProps {
  text: string;
  sx?: SxProps<Theme>;
}

const BludataBluTitle: FC<BludataBluTitleProps> = memo(
  (props) => {
    return (
      <Typography
        variant="subtitle1"
        className={classes.title}
        sx={{
          ...props.sx,
        }}
      >
        {props.text}
      </Typography>
    );
  }
);

export default BludataBluTitle;
