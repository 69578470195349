import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { LicenseInfo } from '@mui/x-data-grid-premium';

LicenseInfo.setLicenseKey(
  "aee9079aa5d0708e65ec1814624bd461Tz03MzcyNCxFPTE3MjUwMjU0MjQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
      <App />
  </Provider>
);
