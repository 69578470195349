import axios from "axios"
import Catalogo from "../../interfaces/catalogo"

export const createCatalogo = (formValues: Catalogo) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/QuickProtettoFile`, formValues)
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default createCatalogo
