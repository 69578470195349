import { Link, Paper, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { ReactComponent as SessionExpiredIcon } from "../../assets/sessione_scaduta.svg"
import { eraseCookie } from "../../lib/cookie"

const SessionExpired = () => {
  const onSubmit = () => {
    eraseCookie('AdminToken')
    sessionStorage.clear()
    window.location.reload()
  }

  return (
    <Stack
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100vh" }}
    >
      <div className="background-layer"></div>
      <Paper
        elevation={1}
        sx={{
          width: "450px",
          height: "300px",
          zIndex: 10,
          backgroundColor: "white",
          position: "relative"
        }}
      >
        <Stack
          flexDirection="column"
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px"
          }}
        >
          <div style={{ height: "100px", width: "100%", textAlign: "center" }}>
            <SessionExpiredIcon />
          </div>
          <Typography
            sx={{ textAlign: "center" }}
            variant="h5"
            fontWeight="bold"
          >
            Sessione Scaduta
          </Typography>
          <Typography
            sx={{ marginTop: "25px", textAlign: "center", mb: 1 }}
            fontWeight="bold"
          >
            La sessione di lavoro è scaduta, è necessario riaccedere alla
            console Catalogo Bludata per continuare le operazioni.
          </Typography>
          <Link
            sx={{
              marginTop: "25px",
              textAlign: "center",
              textDecoration: "none",
              height: "fit-content",
              cursor: "pointer"
            }}
            fontWeight="bold"
            onClick={onSubmit}
          >
            <ArrowForwardIcon sx={{ fontSize: "22px", fontWeight: "bolder" }} />{" "}
            <span
              style={{
                verticalAlign: "top",
                height: "fit-content",
                boxSizing: "border-box"
              }}
            >
              Continua
            </span>
          </Link>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default SessionExpired
