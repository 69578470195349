import * as yup from "yup"

export const schema = yup
  .object()
  .shape({
    eMail: yup
      .string()
      .email("Campo errato in Contatti: Email")
      .max(32, "Campo troppo lungo in Contatti: Email")
      .required(),
    nomeUtente: yup
      .string()
      .max(32, "Campo troppo lungo: Nome Utente")
      .required()
  })
  .required()
