import axios from "axios"
import Catalogo from "../../interfaces/catalogo"

export const fetchCatalogo = () => {
  return new Promise<Catalogo[]>((resolve, reject) => {
    axios
      .get(`/api/QuickProtettoFile`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        return reject("Internal server error: "+ error.message)
      })
  })
}

export default fetchCatalogo
