import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import Gruppo from "../../interfaces/gruppo"

export const fetchGruppo = () => {
  return new Promise<Gruppo[]>((resolve, reject) => {
    axios
      .get(`/api/Gruppo`)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}

export const useGruppo = (key: string = "") => {
  return useQuery({
    queryFn: async () => await fetchGruppo(),
    queryKey: ["groups", key],
    retry: 2,
    retryDelay: 180000
  })
}

export default fetchGruppo
