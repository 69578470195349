import { IconButton, Tooltip } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium"
import DeleteIcon from "@mui/icons-material/Delete"
import DataGridTextField from "../../../UI/DataGridTextField"

const columnsHandler = (
  edit: boolean,
  onValueChange: (id: any, field: string, value: any) => void,
  onDelete: (id: any, field: string, value: any) => void,
  error: string[]
) => {
  return [
    {
      field: "id",
      headerName: "",
      width: 0,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      hide: true
    },
    {
      field: "codiceGruppo",
      headerName: "Codice Gruppo",
      width: 120,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <DataGridTextField
            sx={{ "& .MuiInputBase-input": { textAlign: "center" } }}
            value={params.value || ""}
            onChange={(newValue) =>
              onValueChange(params.id, params.field, newValue)
            }
            disabled={!edit}
          />
        )
      }
    },
    {
      field: "descrizione",
      headerName: "Descrizione",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params: GridRenderCellParams<string>) => {
        return (
          <DataGridTextField
            value={params.value || ""}
            onChange={(newValue) =>
              onValueChange(params.id, params.field, newValue)
            }
            disabled={!edit}
          />
        )
      }
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      editable: true,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const handleDelete = (event: any) => {
          onDelete(params.id, params.field, event.target.value)
        }
        return (
          <IconButton disabled={!edit} color="error" onClick={handleDelete}>
            <Tooltip title="Cancella utente">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )
      }
    }
  ] as GridColDef[]
}
export default columnsHandler
