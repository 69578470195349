import { Divider, List, Stack } from "@mui/material"
import { Dispatch, SetStateAction, memo, useEffect, useState } from "react"
import { TreeItem } from "./treeItem"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { setCurrentTreeSelection, setTreeItemList } from "../../store/root"
import { BluAlert } from "@bludata/components"
import BludataLoaderFullscreen from "../../UI/BludataLoaderFullscreen"
import "./treelist.scss"
import SettingsIcon from "@mui/icons-material/Settings"
import Diversity3Icon from "@mui/icons-material/Diversity3"
import ArticleIcon from "@mui/icons-material/Article"
import PersonIcon from "@mui/icons-material/Person"
import { RootState } from "../../store"
import BludataSearchField from "../../UI/BludataSearchField"

const fetchData = async (
  dispatch: Dispatch<any>,
  setError: Dispatch<SetStateAction<string>>
) => {
  try {
    const res = await axios.get("/api/Global/GetTipi")
    dispatch(setTreeItemList(res.data))
    if (!res.data.length) return
    dispatch(
      setCurrentTreeSelection({
        label: res.data[0].descrizione,
        id: res.data[0].tipo
      })
    )
  } catch (err: any) {
    setError(err || "Internal Server Error")
  }
}

const Tree = memo(() => {
  const { loginData, treeItemList, editing, currentTreeSelection } =
    useSelector((state: RootState) => state.root)
  const dispatch = useDispatch()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState("")
  const [filteredItems, setFilteredItems] = useState(treeItemList)

  useEffect(() => {
    setFilteredItems(
      treeItemList.filter((item) =>
        item.descrizione.toLowerCase().includes(query.toLowerCase())
      )
    )
  }, [query, treeItemList])

  useEffect(() => {
    setLoading(true)
    fetchData(dispatch, setError).finally(() => setLoading(false))
  }, [dispatch])

  return (
    <>
      <BluAlert
        type="error"
        show={!!error}
        handleClose={() => {
          setError("")
        }}
        text={error}
      />
      {loading && <BludataLoaderFullscreen open />}
      {currentTreeSelection && (
        <Stack
          direction="column"
          sx={{ height: "100%", overflow: "hidden" }} // Fai occupare tutto lo spazio disponibile allo Stack
        >
          <BludataSearchField
            label="Ricerca"
            ricerca={query}
            sx={{ p: 1 }}
            disable={editing}
            onRicercaChange={setQuery}
          />
          <List sx={{ flex: 1, overflow: "auto" }}>
            {filteredItems.length > 0 &&
              filteredItems.map((treeItem: any, index: number) => (
                <TreeItem
                  key={`menuRamo${index}`}
                  {...treeItem}
                  icona={<ArticleIcon />}
                />
              ))}
          </List>
          {loginData?.admin && (
            <>
              <Divider sx={{ padding: 1 }} />
              <List
                sx={{
                  flex: "none"
                }}
              >
                <TreeItem
                  key={`menuRamoConfigurazione`}
                  descrizione={"Configurazione"}
                  tipo={"configurazioneGruppi"}
                  icona={<SettingsIcon />}
                >
                  <TreeItem
                    key={`menuRamoConfigurazioneGruppi`}
                    descrizione={"Gruppi"}
                    tipo={"configurazioneGruppi"}
                    icona={<Diversity3Icon />}
                  />
                  <TreeItem
                    key={`menuRamoConfigurazioneUtenti`}
                    descrizione={"Utenti"}
                    tipo={"configurazioneUtenti"}
                    icona={<PersonIcon />}
                  />
                  <TreeItem
                    key={`menuRamoConfigurazioneCataloghi`}
                    descrizione={"Cataloghi"}
                    tipo={"configurazioneCataloghi"}
                    icona={<ArticleIcon />}
                  />
                </TreeItem>
              </List>
            </>
          )}
        </Stack>
      )}
    </>
  )
})

export default Tree
