import { memo } from "react";
import BludataContainerBlock from "./BludataContainerBlock/BludataContainerBlock";
import { IconButton, Stack } from "@mui/material";
import BludataBluTitle from "./BludataBluTitle/BludataBluTitle";
import SyncIcon from "@mui/icons-material/Sync";

interface Props {
  showReload: boolean;
  text: string;
  onRefreshTap?: () => void;
}

function BludataDefaultMainHeader(props: Props) {
  const { showReload, text } = props;

  return (
    <BludataContainerBlock
      sx={{
        margin: "4px",
        height: "46px",
        display: "flex !important",
      }}
    >
      <Stack flex={1} flexDirection="row" alignItems="center">
        <BludataBluTitle
          sx={{
            flex: 1,
          }}
          text={text}
        />
        {showReload && (
          <IconButton color="primary" onClick={props.onRefreshTap}>
            <SyncIcon />
          </IconButton>
        )}
      </Stack>
    </BludataContainerBlock>
  );
}

export default memo(BludataDefaultMainHeader);
