import { useDispatch, useSelector } from "react-redux"
import { RootState } from "./store"
import { BluLoader, BluThemeProvider } from "@bludata/components"
import { Suspense, memo, useMemo } from "react"
import Login from "./components/login/Login"
import SessionExpired from "./components/session-expired/SessionExpired"
import { Home } from "./components/home/home"
import {
  QueryClient,
  QueryClientProvider,
  NetworkMode
} from "@tanstack/react-query"
import "./App.css"
import axios from "axios"
import { setSessionExpired } from "./store/root"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { ResetLanding } from "./components/resetPassword/ResetLanding"

export const CheckSessionExists = memo((): JSX.Element => {
  const { accessToken, sessionExpired } = useSelector(
    (state: RootState) => state.root
  )

  const Content = useMemo(() => {
    if (sessionExpired) return <SessionExpired />
    if (
      accessToken === "" &&
      window.location.pathname.indexOf("resetPassword") < 0
    )
      return <Login />
    return (
      <Suspense fallback={<BluLoader open={true} />}>
        <BrowserRouter>
          <Routes>
            <Route path="/resetPassword" element={<ResetLanding />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    )
  }, [accessToken, sessionExpired])

  return <>{Content}</>
})

function App() {
  const { darkMode } = useSelector((state: RootState) => state.root)
  const dispatch = useDispatch()
  axios.interceptors.response.use(
    function (response) {
      if (response.data.hasOwnProperty("success") && !response.data.success) {
        return Promise.reject(response.data)
      }
      return response
    },
    function (error) {
      if (error.response && error.response.status === 401) {
        dispatch(setSessionExpired(true))
      }
      return Promise.reject(error)
    }
  )

  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              useErrorBoundary: true,
              refetchOnWindowFocus: false,
              refetchOnMount: false,
              refetchOnReconnect: false,
              refetchInterval: 86400000,
              cacheTime: 86400000,
              staleTime: 86400000,
              retry: false,
              networkMode: "always" as NetworkMode
            }
          }
        })
      }
    >
      <BluThemeProvider themeMode={darkMode}>
        <>
          <CheckSessionExists />
        </>
      </BluThemeProvider>
    </QueryClientProvider>
  )
}

export default App
