import axios from "axios"
import UtenteMaster from "../../interfaces/utenteMaster"

export const fetchUtenteMaster = (id: number) => {
  return new Promise<UtenteMaster>((resolve, reject) => {
    axios
      .get(`/api/UtenteMaster/${id}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default fetchUtenteMaster
