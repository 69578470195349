import { Stack } from "@mui/system"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { Box } from "@mui/material"
import { memo } from "react"
import axios from "axios"
import Footer from "../footer/footer"
import Tree from "../tree/tree"
import Header from "../header/header"
import { CentroOttico } from "../centroOttico/CentroOttico"
import { ConfigurazioneUtenti } from "../configurazione/configurazioneUtenti/ConfigurazioneUtenti"
import { ConfigurazioneGruppi } from "../configurazione/configurazioneGruppi/ConfigurazioneGruppi"
import { ConfigurazioneCataloghi } from "../configurazione/configurazioneCataloghi/ConfigurazioneCataloghi"

export const Home = memo(() => {
  const { accessToken, currentTreeSelection } = useSelector(
    (state: RootState) => state.root
  )
  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  if (accessToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
  } else {
    axios.defaults.headers.common["Authorization"] = ""
  }

  return (
    <Stack
      flex="1"
      flexDirection="column"
      style={{ width: "100%", height: "100vh" }}
    >
      <>
        <Header />
        <Stack
          flex="1"
          flexDirection="row"
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          <Box
            sx={{
              backgroundColor: "var(--base-foreground-color)",
              borderRight: "1px solid var(--divider-color) !important"
            }}
          >
            <Tree />
          </Box>
          {currentTreeSelection?.id.indexOf("configurazione") !== 0 && (
            <CentroOttico />
          )}
          {currentTreeSelection?.id === "configurazioneUtenti" && (
            <ConfigurazioneUtenti />
          )}
          {currentTreeSelection?.id === "configurazioneGruppi" && (
            <ConfigurazioneGruppi />
          )}
          {currentTreeSelection?.id === "configurazioneCataloghi" && (
            <ConfigurazioneCataloghi />
          )}
        </Stack>
        <Footer />
      </>
    </Stack>
  )
})
