import { Box, SxProps, Theme } from "@mui/material";
import { FC } from "react";
import classes from "./BludataContainerBlock.module.css";

interface BludataContainerBlockProps {
  children: JSX.Element|JSX.Element[];
  sx?: SxProps<Theme>;
}

const BludataContainerBlock: FC<BludataContainerBlockProps> = (props) => {
  return (
    <Box
      className={classes.block}
      sx={{
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
};

export default BludataContainerBlock;
