import axios from "axios"
import Utente from "../../interfaces/utente"

export const fetchListaUtenti = (
  tipo: string,
  page: number,
  pageSize: number,
  ragioneSociale?: string
) => {
  return new Promise<{
    totaleUtenti: number
    totalePagine: number
    paginaCorrente: number
    dimensionePagine: number
    utenteOttico: Utente[]
  }>((resolve, reject) => {
    axios
      .get("/api/Global/GetListaUtenti", {
        params: {
          tipo: tipo,
          page: page,
          pageSize: pageSize,
          ragioneSociale: ragioneSociale
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default fetchListaUtenti
