import { Button, Divider, Paper, Stack, Typography } from "@mui/material"
import { memo, useState } from "react"
import { BluLoader, BluTextField } from "@bludata/components"
import setConfermaResetPassword from "../../api/utenteMaster/setConfermaResetPassword"
import CheckIcon from "@mui/icons-material/Check"
import LogoutIcon from "@mui/icons-material/Logout"
import { eraseCookie } from "../../lib/cookie"
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied"

import { createBrowserHistory } from "history"
import isValidPassword from "../../lib/isValidPassword"

export const history = createBrowserHistory()

export const ResetLanding = memo((): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [resultReset, setResultReset] = useState(false)

  const url = new URL(window.location.href)
  const token = url.searchParams.get("token")
  const [error, setError] = useState(false)

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const enablePassword = !(
    password === confirmPassword && isValidPassword(password)
  )
  
  return (
    <Stack
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100vh", position: "relative" }}
    >
      <BluLoader open={loading} />
      <div className="background-layer"></div>
      {token && !error ? (
        <>
          {!resultReset ? (
            <>
              <Paper
                elevation={1}
                sx={{
                  width: "450px",
                  height: "250px",
                  zIndex: 10,
                  backgroundColor: "white",
                  position: "relative"
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="start"
                  sx={{
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    right: "20px",
                    bottom: "20px"
                  }}
                >
                  <Typography sx={{ textAlign: "center" }} variant="h5">
                    Catalogo Bludata
                  </Typography>
                  <BluTextField
                    id="password"
                    type="password"
                    label="Nuova Password*"
                    fullWidth
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  />
                  <BluTextField
                    id="confermapassword"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    label="Conferma Password*"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }}
                  />
                  <Divider sx={{ opacity: 0, mb: "10px!important" }} />
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    justifyContent={"center"}
                  >
                    <Button
                      disabled={enablePassword}
                      onClick={() => {
                        setLoading(true)
                        setConfermaResetPassword(password, token!)
                          .catch((err) => {
                            setError(err || "Internal Server Error")
                          })
                          .then(() => {
                            setLoading(false)
                            setResultReset(true)
                          })
                      }}
                      color="primary"
                    >
                      Cambia password
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </>
          ) : (
            <>
              <Paper
                elevation={1}
                sx={{
                  width: "450px",
                  height: "350px",
                  zIndex: 10,
                  backgroundColor: "white",
                  position: "relative"
                }}
              >
                <Stack
                  flexDirection="column"
                  alignItems="center"
                  sx={{
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    right: "20px",
                    bottom: "20px"
                  }}
                >
                  <CheckIcon color="success" sx={{ fontSize: "9rem" }} />
                  <Typography sx={{ marginTop: "25px", mb: 1 }} variant="h6">
                    Procedura completata
                  </Typography>

                  <Typography
                    sx={{ marginTop: "25px", mb: 1, textAlign: "center" }}
                    variant="body1"
                  >
                    Il reset password è stato eseguito con successo
                  </Typography>
                  <Button
                    variant="outlined"
                    startIcon={<LogoutIcon />}
                    onClick={() => {
                      eraseCookie("AdminToken")
                      history.replace("/")
                      window.location.reload()
                    }}
                  >
                    Torna al login
                  </Button>
                </Stack>
              </Paper>
            </>
          )}
        </>
      ) : (
        <>
          <Paper
            elevation={1}
            sx={{
              width: "450px",
              height: "350px",
              zIndex: 10,
              backgroundColor: "white",
              position: "relative"
            }}
          >
            <Stack
              flexDirection="column"
              alignItems="center"
              sx={{
                position: "absolute",
                top: "20px",
                left: "20px",
                right: "20px",
                bottom: "20px"
              }}
            >
              <SentimentVeryDissatisfiedIcon
                color="error"
                sx={{ fontSize: "9rem" }}
              />
              <Typography sx={{ marginTop: "25px", mb: 1 }} variant="h6">
                Attenzione
              </Typography>

              <Typography
                sx={{ marginTop: "25px", mb: 1, textAlign: "center" }}
                variant="body1"
              >
                Non è possibile resettare la password al momento. Contatta
                Bludata Informatica per ricevere assistenza.
              </Typography>
            </Stack>
          </Paper>
        </>
      )}
      <Stack
        className="my-footer"
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
      >
        <Typography variant="body1" sx={{ marginRight: "10px" }}>
          &#169; {new Date().getFullYear()} Bludata Informatica S.r.l.
        </Typography>
        <img
          style={{
            marginRight: "10px",
            width: "auto"
          }}
          src="https://focus.bludata.com/logo_bludata_50.png"
          alt="Logo Bludata Informatica S.r.l."
        />
      </Stack>
    </Stack>
  )
})
