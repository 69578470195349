import axios from "axios"

export const setModificaPassword = (
  idUtente: number,
  oldPassword: string,
  newPassword: string
) => {
  return new Promise((resolve, reject) => {
    const md5 = require("md5")
    axios
      .post(`/api/UtenteMaster/ModificaPassword`, {
        idUtente,
        oldPassword: md5(oldPassword),
        newPassword: md5(newPassword)
      })
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        reject(error.response.data.response)
      })
  })
}
export default setModificaPassword
