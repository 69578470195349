import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { FC, memo } from "react";

interface BludataLoaderFullscreenProps {
  open: boolean;
}

const BludataLoaderFullscreen: FC<BludataLoaderFullscreenProps> = memo(
  (props) => {
    return (
      <Backdrop
        sx={{
          zIndex: 9999999999999999,
          backgroundColor: "rgba(0, 0, 0, 0.05)",
        }}
        open={props.open}
      >
        <CircularProgress color="primary" thickness={1.5} size={"120px"} />
      </Backdrop>
    );
  }
);

export default BludataLoaderFullscreen;
