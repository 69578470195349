import { FC, useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import { SxProps } from "@mui/material"

interface DataGridTextFieldProps {
  value: string
  onChange: (value: string) => void
  onBlur?: () => void
  disabled: boolean
  sx?:SxProps
}

const DataGridTextField: FC<DataGridTextFieldProps> = ({
  value: propValue,
  onChange,
  onBlur,
  disabled,
  sx
}) => {
  const [value, setValue] = useState(propValue)

  useEffect(() => {
    setValue(propValue)
  }, [propValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    onChange(event.target.value)
  }

  const handleBlur = () => {
    onChange(value)
    if (onBlur) {
      onBlur()
    }
  }
  

  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          fontWeight: "400!important",
          border: "none!important"
        },
        "& .Mui-focused:after": { border: "none!important" },
        "& .MuiInput-underline:after": { border: "none!important" },
        ...sx
      }}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      fullWidth
      onKeyDown={(event) => {
        event.stopPropagation()
      }}
    />
  )
}

export default DataGridTextField
