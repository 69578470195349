import * as yup from "yup"
import controlloPivaCodFisc from "../../lib/controlloPivaCodFisc"

const isNumber = /^(\d+|)$/

export const schema = yup
  .object()
  .shape({
    partitaIVA: yup
      .string()
      .test(
        "partitaIva",
        "Campo errato in generali: Partita IVA",
        (value: any) => {
          return !value
            ? true
            : controlloPivaCodFisc(value || "", false, false) === true
        }
      )
      .nullable(),
    cap: yup
      .string()
      .test(
        "len",
        "Campo errato in generali: CAP",
        (val) => val !== undefined && val.length <= 5 && isNumber.test(val)
      )
  })
  .required()
