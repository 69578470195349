import { Button, Paper, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { useDispatch } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import axios from "axios"
import { BluAlert, BluLoader } from "@bludata/components"
import { useEffect, useState } from "react"
import { setAccessToken, setLoginData } from "../../store/root"
import { setCookie } from "../../lib/cookie"
import Footer from "../footer/footer"
import "./login.css"

export interface LoginForm {
  userName: string
  password: string
}

const Login = () => {
  const dispatch = useDispatch()

  const [badLogin, setBadLogin] = useState("")
  const [loading, setLoading] = useState(false)
  const md5 = require("md5")

  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.defaults.headers.common["Authorization"] = undefined

  const urlParams = new URLSearchParams(window.location.search)

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: {
      userName: localStorage.getItem("AdminUsername") || "",
      password: ""
    }
  })

  useEffect(() => {
    if (urlParams.has("userName")) {
      setValue("userName", urlParams.get("userName") || "")
    }
  })

  const onSubmit = (data: LoginForm) => {
    /*
      Username = admin
      Pass = 12345678
    */
    setLoading(true)

    axios
      .post("/api/authenticate", {
        userName: data.userName,
        password: md5(data.password)
      })
      .then(
        (res: {
          data: {
            token: string
            gruppo: string
            admin: boolean
            nomeUtente: string
            id: number
          }
        }) => {
          localStorage.setItem("AdminUsername", data.userName)
          dispatch(setAccessToken(res.data.token))
          setCookie("AdminToken", res.data.token)
          setCookie("AdminId", res.data.id)
          setCookie("AdminGruppo", res.data.gruppo)
          setCookie("AdminNomeUtente", res.data.nomeUtente)
          dispatch(
            setLoginData({
              userName: res.data.nomeUtente,
              admin: res.data.admin,
              gruppo: res.data.gruppo,
              id: res.data.id
            })
          )
        }
      )
      .catch((err) => {
        setBadLogin(
          !!err
            ? err.response.data.response
            : "Utente o password errati"
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Stack
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100vh" }}
    >
      <BluLoader open={loading} />
      <BluAlert
        type="error"
        show={!!badLogin}
        text={badLogin}
        handleClose={() => {
          setBadLogin("")
        }}
      />
      <div className="background-layer"></div>
      <Paper
        elevation={1}
        sx={{
          width: "450px",
          height: "300px",
          zIndex: 10,
          backgroundColor: "white",
          position: "relative"
        }}
      >
        <Stack
          flexDirection="column"
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px"
          }}
        >
          <Typography sx={{ textAlign: "center" }} variant="h5">
            Catalogo Bludata
          </Typography>
          <Typography sx={{ marginTop: "25px", mb: 1 }} variant="body1">
            Inserisci le tue credenziali per accedere.
          </Typography>

          <form
            style={{
              flex: 1,
              display: "flex"
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack
              flex={1}
              flexDirection="column"
              spacing={1}
              justifyContent="center"
            >
              <Controller
                name="userName"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    error={!!errors.userName}
                    autoComplete="userName"
                    label="Utente"
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Password"
                    error={!!errors.password}
                    type="password"
                    autoComplete="current-password"
                    {...field}
                  />
                )}
              />
              <Stack flexDirection="row" justifyContent="end">
                <Button type="submit" sx={{ mt: "10px" }} variant="contained">
                  Accedi
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Paper>
      <Footer
        sx={{
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0"
        }}
      />
    </Stack>
  )
}

export default Login
