import { FC, MutableRefObject } from "react"
import { DataGridPremium, GridRowId } from "@mui/x-data-grid-premium"
import UtenteMaster from "../../../../interfaces/utenteMaster"
import Gruppo from "../../../../interfaces/gruppo"
import columnsHandler from "../columns"
import { useSelector } from "react-redux"
import { RootState } from "../../../../store"
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium"
import { useDebounce } from "../../../../lib/useDebounce"

interface UsersDataGridProps {
  gridApiRef: MutableRefObject<GridApiPremium>
  groupList: Gruppo[]
  userList: {
    currentPage: number
    pageSize: number
    totalItems: number
    totalPages: number
    users: UtenteMaster[]
  }
  page: number
  onEditingCell: (id: GridRowId, field: string) => void
  onEdit: (id: number, field: string, value: any) => void
  onDelete: (id: number, field: string, value: any) => void
  onPageChange: (currentPage: number) => void
  onResetPasswordUser: (eMail: string, user: string) => void
}

const UsersDataGrid: FC<UsersDataGridProps> = ({
  gridApiRef,
  groupList,
  userList,
  page,
  onEditingCell,
  onEdit,
  onDelete,
  onPageChange,
  onResetPasswordUser
}) => {
  const { editing } = useSelector((state: RootState) => state.root)

  const handleCellChange = useDebounce((id, field, value) => {
    onEdit(id, field, value)
  }, 1000)

  return (
    <DataGridPremium
      apiRef={gridApiRef}
      columns={columnsHandler(
        editing,
        groupList,
        handleCellChange,
        (id, field, value) => {
          onDelete(id, field, value)
        },
        (eMail, user) => {
          onResetPasswordUser(eMail, user)
        }
      )}
      rows={!!userList ? userList.users : []}
      hideFooterSelectedRowCount
      rowsPerPageOptions={[!!userList ? userList.pageSize : 10]}
      paginationMode="server"
      rowCount={!!userList ? userList.totalPages : 0}
      sx={{
        "& .MuiDataGrid-cell": {
          outline: "none!important"
        },
        "& .MuiInputBase-root": {
          border: "none!important"
        },
        mt: 1
      }}
      onCellEditStart={(field) => {
        onEditingCell(field.id, field.field)
      }}
      onCellClick={(field) => {
        onEditingCell(field.id, field.field)
      }}
    />
  )
}

export default UsersDataGrid
