import {
  GridColDef
} from "@mui/x-data-grid-premium"

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "",
    width: 20,
    headerAlign: "left",
    align: "center",
    hide:true
  },
  {
    field: "ragioneSociale",
    headerName: "Ragione sociale",
    flex: 1,
    minWidth:150,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "left",
    align: "left"
  },
  {
    field: "userId",
    headerName: "Utente",
    width: 150,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "left",
    align: "left"
  },
  {
    field: "indirizzo",
    headerName: "Indirizzo",
    width: 250,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "left",
    align: "left"
  },
  {
    field: "cap",
    headerName: "CAP",
    width: 100,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "left",
    align: "left"
  },
  {
    field: "citta",
    headerName: "Città",
    width: 250,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "left",
    align: "left"
  },
  {
    field: "provincia",
    headerName: "Prov.",
    width: 60,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "partitaIVA",
    headerName: "Partita IVA",
    width: 200,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    headerAlign: "left",
    align: "left"
  }
]
export default columns
