import { Stack } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { Box, Button, Card, Typography } from "@mui/material"
import { memo, useEffect, useState } from "react"
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium"
import BludataDefaultMainHeader from "../../../UI/BludataDefaultMainHeader"
import { BluAlert, BluLoader } from "@bludata/components"
import columnsHandler from "./columns"
import { customAlphabet } from "nanoid"
import Catalogo from "../../../interfaces/catalogo"
import fetchCatalogo from "../../../api/catalogo/fetchCatalogo"
import createCatalogo from "../../../api/catalogo/createCatalogo"
import updateCatalogo from "../../../api/catalogo/updateCatalogo"
import deleteCatalogo from "../../../api/catalogo/deleteCatalogo"
import fetchGruppo from "../../../api/gruppi/fetchGruppo"
import Gruppo from "../../../interfaces/gruppo"
import createFile from "../../../api/file/createFile"
import { setTreeItemList, toggleEnableTree } from "../../../store/root"
import { useDebounce } from "../../../lib/useDebounce"

export const ConfigurazioneCataloghi = memo(() => {
  const numberAlphabet = "0123456789"
  const { loginData, editing } = useSelector((state: RootState) => state.root)
  const [catalogoList, setCatalogoList] = useState<Catalogo[]>([])
  const [fileTooBig, setFileTooBig] = useState(false)
  const [filesToSend, setfilesToSend] = useState<
    { file: File; tipo: string }[]
  >([])
  const [group, setGroup] = useState<Gruppo[]>([])
  const [loading, setLoading] = useState(false)
  const [editingCell, setEditingCell] = useState<any>(null)
  const [editedRow, setEditedRow] = useState<number[]>([])
  const gridApiRef = useGridApiRef()
  const [error, setError] = useState("")
  const requiredFields = ["descrizione", "gruppi", "tipo"]
  const [success, setSuccess] = useState("")
  const dispatch = useDispatch()
  const [errorCataloghi, setErrorCataloghi] = useState<{
    id: any
    field: string
    value: any
  }>()

  const [errorDatagrid, setErrorDtagrid] = useState<string[]>([])

  const reloadCatalogo = () => {
    if (loginData?.admin) {
      setLoading(true)
      fetchCatalogo()
        .then((res) => {
          setLoading(false)
          setCatalogoList(res)
          dispatch(
            setTreeItemList(
              res.map((item) => {
                return {
                  gruppo: item.gruppi[0].gruppo,
                  tipo: item.tipo,
                  file: item.file,
                  descrizione: item.descrizione
                }
              })
            )
          )
        })
        .catch((err) => {
          setError(err || "Internal Server Error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const reloadGroups = () => {
    if (loginData?.admin) {
      setLoading(true)
      fetchGruppo()
        .then((res) => {
          setLoading(false)
          setGroup(res)
        })
        .catch((err) => {
          setError(err || "Internal Server Error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    reloadCatalogo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (editing) reloadGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing])

  const toggleEdit = () => {
    dispatch(toggleEnableTree())
  }

  const onSubmit = () => {
    setLoading(true)

    let newRows = [...editedRow]

    if (
      editingCell !== null &&
      gridApiRef.current !== undefined &&
      editedRow.length
    ) {
      gridApiRef.current.setCellMode(editingCell.id, editingCell.field, "edit")
      if (
        gridApiRef.current.getCellMode(editingCell.id, editingCell.field) ===
        "edit"
      ) {
        gridApiRef.current.commitCellChange(editingCell)

        const isIdPresent = newRows.includes(editingCell.id)
        if (!isIdPresent) {
          newRows = [...newRows, editingCell.id]
        }

        setEditedRow(newRows)

        gridApiRef.current.setCellMode(
          editingCell.id,
          editingCell.field,
          "view"
        )
        setEditingCell(null)
      }
    }

    let cataloghiPromise = editedRow.length
      ? newRows.map((id) => {
          const currentCatalogo = catalogoList!.find(
            (catalogo) => catalogo.id === id
          )
          if (currentCatalogo && currentCatalogo.create) {
            return createCatalogo({
              id: undefined,
              tipo: currentCatalogo.tipo,
              file: currentCatalogo.file,
              tabella: currentCatalogo.tabella,
              descrizione: currentCatalogo.descrizione,
              gruppi: currentCatalogo.gruppi
            })
          } else if (currentCatalogo) {
            return updateCatalogo(id, currentCatalogo)
          }
          return Promise.resolve()
        })
      : []

    let filesPromise = () =>
      newRows.map((id) => {
        const currentCatalogo = catalogoList!.find(
          (catalogo) => catalogo.id === id
        )

        if (currentCatalogo) {
          const file = filesToSend.find(
            (file) => file.tipo === currentCatalogo.tipo
          )?.file

          if (file) {
            return createFile(file, currentCatalogo.tipo)
          } else {
            return Promise.resolve()
          }
        }
        return Promise.resolve()
      })

    Promise.all([...cataloghiPromise])
      .then(() => {
        if (editedRow.length === 0) return
        setSuccess(
          editedRow.length > 1
            ? "Cataloghi salvati con successo"
            : "Catalogo salvato con successo"
        )
        if (filesToSend) {
          return Promise.all(filesPromise()).catch((err) => {
            setError(err || "Errore durante il salvataggio del catalogo")
          })
        }
      })
      .catch((err) => {
        setError(err || "Internal Server Error")
      })
      .finally(() => {
        setLoading(false)
        setEditingCell(null)
        setEditedRow([])
        toggleEdit()
        reloadCatalogo()
      })
  }

  const handleCellChange = useDebounce((id, field, value) => {
    setCatalogoList((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          setErrorDtagrid((prevErrors) => {
            const isFieldPresent = prevErrors.includes(field)
            if (value.length > 0 && isFieldPresent) {
              return prevErrors.filter((error) => error !== field)
            } else if (value.length === 0 && !isFieldPresent) {
              return [...prevErrors, field]
            }
            return prevErrors
          })
          setEditedRow((prev) => {
            const isIdPresent = prev.includes(id)
            if (!isIdPresent) {
              return [...prev, id]
            }
            return prev
          })
          return { ...row, [field]: value }
        }
        return row
      })
    })
  }, 1000)

  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",
        backgroundColor: "var(--hovered-background-color)"
      }}
    >
      <BluAlert
        show={!!success}
        handleClose={() => {
          setSuccess("")
        }}
        text={success}
        type="success"
      />
      <BluAlert
        show={!!error}
        handleClose={() => {
          setError("")
        }}
        text={error}
        type="error"
      />
      <BluAlert
        show={fileTooBig}
        handleClose={() => {
          setFileTooBig(false)
        }}
        text={"Il file che hai inserito super i 20MB"}
        type="warning"
      />
      <BluAlert
        show={errorCataloghi !== undefined}
        handleClose={() => {
          setErrorCataloghi(undefined)
        }}
        handleConfirm={() => {
          if (
            catalogoList?.filter(
              (catalogo) => catalogo.id === errorCataloghi?.id
            )[0].create
          ) {
            setEditedRow((x) => {
              return [...x].filter((id) => id !== errorCataloghi?.id)
            })
            setCatalogoList((prevRows) => {
              return prevRows!.filter(
                (deletedItem) => deletedItem.id !== errorCataloghi?.id
              )
            })
            setErrorCataloghi(undefined)
          } else {
            setLoading(true)
            deleteCatalogo(errorCataloghi?.id)
              .then(() => {
                setSuccess("Catalogo eliminato con successo.")
              })
              .catch((err) => {
                setError(err || "Errore durante l'eliminazione")
                setLoading(false)
              })
              .finally(() => {
                setLoading(false)
                setEditedRow((x) => {
                  return [...x].filter(
                    (deletedItem) => deletedItem !== errorCataloghi?.id
                  )
                })
                setCatalogoList((prevRows) => {
                  return prevRows!.filter(
                    (deletedItem) => deletedItem.id !== errorCataloghi?.id
                  )
                })
                setErrorCataloghi(undefined)
              })
          }
        }}
        text={
          "Sei sicuro di voler eliminare questo Catalogo da Catalogo Bludata?"
        }
        type="warning"
      />
      <Stack flexDirection="column" flex={1} sx={{ height: "100%" }}>
        <BludataDefaultMainHeader
          showReload={!editing}
          text={"Configurazione / Cataloghi"}
          onRefreshTap={reloadCatalogo}
        />
        <Card variant="outlined" sx={{ height: "100%", p: 1, m: 0.5, mt: 0 }}>
          <Stack direction="column" spacing={1} sx={{ height: "100%" }}>
            <BluLoader open={loading} />
            <Typography>
              In questa sezione è possibile configurare i cataloghi. I campi
              Descrizione, Gruppi e Tipo sono obbligatori.
            </Typography>
            {loginData?.admin && (
              <>
                <DataGridPremium
                  apiRef={gridApiRef}
                  disableSelectionOnClick
                  columns={columnsHandler(
                    editing,
                    group,
                    handleCellChange,
                    (id, field, value) => {
                      setErrorCataloghi({ id, field, value })
                    },
                    (file, tipo, id) => {
                      setEditedRow((prevRows: any) => {
                        const isIdPresent = prevRows.includes(id)
                        if (!isIdPresent) {
                          return [...prevRows, id]
                        }
                        return prevRows
                      })

                      setfilesToSend((prevFiles: any) => {
                        const isFilePresent = prevFiles.some(
                          (item: any) => item.tipo === tipo
                        )
                        if (!isFilePresent) {
                          return [...prevFiles, { file: file, tipo: tipo }]
                        }
                        return prevFiles
                      })
                    },
                    () => {
                      setFileTooBig(true)
                    }
                  )}
                  rows={!!catalogoList ? catalogoList : []}
                  hideFooterSelectedRowCount
                  sx={{
                    "& .MuiDataGrid-cell": {
                      outline: "none!important"
                    },
                    "& .MuiInputBase-root": {
                      border: "none!important"
                    },
                    mt: 1
                  }}
                  onCellEditStart={(field) => {
                    setEditingCell({ id: field.id, field: field.field })
                  }}
                  onCellClick={(field) => {
                    setEditingCell({ id: field.id, field: field.field })
                  }}
                />
              </>
            )}
            {editing ? (
              <Stack
                direction={"row"}
                spacing={0.5}
                justifyContent={"flex-end"}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    const id = Number(customAlphabet(numberAlphabet, 10)())
                    setCatalogoList((prev) => {
                      return [
                        ...prev,
                        {
                          id: id,
                          tipo: "",
                          file: "",
                          tabella: null,
                          descrizione: "",
                          create: true,
                          gruppi: []
                        }
                      ]
                    })
                    setErrorDtagrid(requiredFields)
                    setTimeout(() => {
                      gridApiRef.current.selectRow(id)
                    }, 100)
                  }}
                >
                  Nuovo Catalogo
                </Button>
                <Box width={20}></Box>
                <Button
                  onClick={() => {
                    reloadCatalogo()
                    toggleEdit()
                  }}
                  variant="outlined"
                >
                  Annulla
                </Button>
                <Button
                  onClick={
                    !(errorDatagrid.length === 0)
                      ? () => {
                          setError(
                            "I campi Descrizione, Gruppi e Tipo sono obbligatori"
                          )
                        }
                      : onSubmit
                  }
                >
                  Salva
                </Button>
              </Stack>
            ) : (
              <Stack direction={"row"} justifyContent={"end"} spacing={0.5}>
                <Button onClick={toggleEdit} variant="outlined">
                  Modifica
                </Button>
              </Stack>
            )}
          </Stack>
        </Card>
      </Stack>
    </Box>
  )
})
