import { Stack } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { Box, Button, Card, Typography } from "@mui/material"
import { memo, useEffect, useState } from "react"
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium"
import BludataDefaultMainHeader from "../../../UI/BludataDefaultMainHeader"
import { BluAlert, BluLoader } from "@bludata/components"
import columnsHandler from "./columns"
import fetchGruppo from "../../../api/gruppi/fetchGruppo"
import Gruppo from "../../../interfaces/gruppo"
import createGruppi from "../../../api/gruppi/createGruppi"
import updateGruppo from "../../../api/gruppi/updateGruppo"
import deleteGruppo from "../../../api/gruppi/deleteGruppo"
import { customAlphabet } from "nanoid"
import { toggleEnableTree } from "../../../store/root"
import { useDebounce } from "../../../lib/useDebounce"

export const ConfigurazioneGruppi = memo(() => {
  const numberAlphabet = "0123456789"
  const { loginData, editing } = useSelector((state: RootState) => state.root)
  const [groupList, setGroupList] = useState<Gruppo[]>([])
  const [loading, setLoading] = useState(false)
  const [editingCell, setEditingCell] = useState<any>(null)
  const [editedRow, setEditedRow] = useState<number[]>([])
  const gridApiRef = useGridApiRef()
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const dispatch = useDispatch()
  const requiredFields = ["descrizione", "codiceGruppo"]
  const [deleteGroup, setDeleteGroup] = useState<{
    id: any
    field: string
    value: any
  }>()

  const [errorDatagrid, setErrorDtagrid] = useState<string[]>([])

  const reloadGroup = () => {
    if (loginData?.admin) {
      setLoading(true)
      fetchGruppo()
        .then((res) => {
          setLoading(false)
          setGroupList(res)
        })
        .catch((err) => {
          setError(err || "Internal Server Error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleAnnulla = () => {
    reloadGroup()
    setEditedRow([])
    setEditingCell(null)
    setErrorDtagrid([])
    toggleEdit()
  }

  useEffect(() => {
    reloadGroup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleEdit = () => {
    dispatch(toggleEnableTree())
  }

  const restart = () => {
    setLoading(false)
    setEditingCell(null)
    setEditedRow([])
    toggleEdit()
    reloadGroup()
  }

  const onSubmit = () => {
    setLoading(true)
    let newRows = [...editedRow]
    const handlePromiseError = (promise: any) => {
      return promise.catch((err: any) => ({ error: err }))
    }
    
    if (
      editingCell !== null &&
      gridApiRef.current !== undefined &&
      editedRow.length
    ) {
      gridApiRef.current.setCellMode(editingCell.id, editingCell.field, "edit")
      if (
        gridApiRef.current.getCellMode(editingCell.id, editingCell.field) ===
        "edit"
      ) {
        gridApiRef.current.commitCellChange(editingCell)

        const isIdPresent = newRows.includes(editingCell.id)
        if (!isIdPresent) {
          newRows = [...newRows, editingCell.id]
        }

        setEditedRow(newRows)

        gridApiRef.current.setCellMode(
          editingCell.id,
          editingCell.field,
          "view"
        )
        setEditingCell(null)
      }
    }

    let groupPromises = editedRow.length
      ? newRows.map((id) => {
          const currentGroup = groupList!.find((group) => group.id === id)
          if (currentGroup && currentGroup.create) {
            return handlePromiseError(
              createGruppi({
                id: undefined,
                codiceGruppo: currentGroup.codiceGruppo,
                descrizione: currentGroup.descrizione
              })
            )
          } else if (currentGroup) {
            return handlePromiseError(updateGruppo(id, currentGroup))
          }
          return Promise.resolve()
        })
      : []

    Promise.all([...groupPromises])
      .then(() => {
        if (editedRow.length === 0) return
        setSuccess(
          editedRow.length > 1
            ? "Gruppi salvati con successo"
            : "Gruppo salvato con successo"
        )
      })
      .catch((err) => {
        setError(err || "Internal Server Error")
      })
      .finally(() => {
        restart()
      })
  }

  const handleCellChange = useDebounce((id, field, value) => {
    setGroupList((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value };
        }
        return row;
      });
    });
  }, 1000); 

  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",
        backgroundColor: "var(--hovered-background-color)"
      }}
    >
      <BluAlert
        show={!!error}
        handleClose={() => {
          setError("")
        }}
        text={error}
        type="error"
      />
      <BluAlert
        show={!!success}
        handleClose={() => {
          setSuccess("")
        }}
        text={success}
        type="success"
      />
      <BluAlert
        show={deleteGroup !== undefined}
        handleClose={() => {
          setDeleteGroup(undefined)
        }}
        handleConfirm={() => {
          if (
            groupList?.filter((group) => group.id === deleteGroup?.id)[0].create
          ) {
            setEditedRow((x) => {
              return [...x].filter((id) => id !== deleteGroup?.id)
            })
            setGroupList((prevRows) => {
              return prevRows!.filter(
                (deletedItem) => deletedItem.id !== deleteGroup?.id
              )
            })
            setEditingCell(null)
          } else {
            setLoading(true)
            deleteGruppo(deleteGroup?.id)
              .then(() => {
                setSuccess("Gruppo eliminato con successo")
              })
              .catch((err) => {
                setError(err||"Errore durante l'eliminazione")
                setLoading(false)
              })
              .finally(() => {
                setLoading(false)
                setEditedRow((x) => x.filter((id) => id !== deleteGroup?.id))
                setEditingCell(null)
                setGroupList((prevRows) => {
                  return prevRows!.filter(
                    (deletedItem) => deletedItem.id !== deleteGroup?.id
                  )
                })
                setDeleteGroup(undefined)
              })
          }
        }}
        text={
          "Sei sicuro di voler eliminare questo gruppo da Catalogo Bludata?"
        }
        type="warning"
      />
      <Stack flexDirection="column" flex={1} sx={{ height: "100%" }}>
        <BludataDefaultMainHeader
          showReload={!editing}
          text={"Configurazione / Gruppi"}
          onRefreshTap={reloadGroup}
        />
        <Card variant="outlined" sx={{ height: "100%", p: 1, m: 0.5, mt: 0 }}>
          <Stack direction="column" spacing={1} sx={{ height: "100%" }}>
            <BluLoader open={loading} />
            <Typography>
              In questa sezione è possibile aggiungere dei nuovi gruppi. I campi
              Codice Gruppo e Descrizione sono obbligatori.
            </Typography>
            {loginData?.admin && (
              <>
                <DataGridPremium
                  apiRef={gridApiRef}
                  columns={columnsHandler(
                    editing,
                    handleCellChange,
                    (id, field, value) => {
                      setDeleteGroup({ id, field, value })
                    },
                    errorDatagrid
                  )}
                  rows={!!groupList ? groupList : []}
                  hideFooterSelectedRowCount
                  rowCount={!!groupList ? groupList.length : 0}
                  sx={{
                    "& .MuiDataGrid-cell": {
                      outline: "none!important"
                    },
                    "& .MuiInputBase-root": {
                      border: "none!important"
                    },
                    mt: 1
                  }}
                  onCellEditStart={(field) => {
                    setEditingCell({ id: field.id, field: field.field })
                  }}
                  onCellClick={(field) => {
                    setEditingCell({ id: field.id, field: field.field })
                  }}
                />
              </>
            )}
            {editing ? (
              <Stack direction={"row"} spacing={0.5}>
                <Box flex={1}></Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    const id = Number(customAlphabet(numberAlphabet, 10)())
                    setGroupList((prev) => {
                      return [
                        ...prev,
                        {
                          id: id,
                          codiceGruppo: "",
                          descrizione: "",
                          create: true
                        }
                      ]
                    })
                    setErrorDtagrid(requiredFields)
                    setTimeout(() => {
                      gridApiRef.current.selectRow(id)
                    }, 100)
                  }}
                >
                  Nuovo Gruppo
                </Button>
                <Box width={20}></Box>
                <Button onClick={handleAnnulla} variant="outlined">
                  Annulla
                </Button>
                <Button
                  onClick={
                    !(errorDatagrid.length <= 0)
                      ? () => {
                          setError(
                            "I campi Codice Gruppo e Descrizione sono obbligatori"
                          )
                        }
                      : onSubmit
                  }
                >
                  Salva
                </Button>
              </Stack>
            ) : (
              <Stack direction={"row"} justifyContent={"end"} spacing={0.5}>
                <Button onClick={toggleEdit} variant="outlined">
                  Modifica
                </Button>
              </Stack>
            )}
          </Stack>
        </Card>
      </Stack>
    </Box>
  )
})
