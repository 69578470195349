import { BluAlert, BluDialog, BluLoader, BluTitle } from "@bludata/components"
import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import { FC, useState } from "react"
import LockIcon from "@mui/icons-material/Lock"
import LogoutIcon from "@mui/icons-material/Logout"
import PersonIcon from "@mui/icons-material/Person"
import { eraseCookie } from "../../../lib/cookie"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import UtenteMaster from "../../../interfaces/utenteMaster"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormInputText } from "../../../UI/UseFormComponents/FormInputText"
import updateUtenteMaster from "../../../api/utenteMaster/updateUtenteMaster"
import PasswordDialog from "./PasswordDialog"
import { schema } from "./schema"
import setModificaPassword from "../../../api/utenteMaster/setModificaPassword"
import { setUser } from "../../../store/root"

const UserDialog: FC<{
  show: boolean
  onClose: () => void
  user: UtenteMaster
}> = (props) => {
  const { loginData } = useSelector((state: RootState) => state.root)
  const { show, onClose, user } = props
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [logout, setLogout] = useState(false)
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false)
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const methods = useForm<any>({
    defaultValues: user,
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema)
  })

  const {
    control,
    setValue,
    formState: { errors },
    getValues
  } = methods

  const handleClose = (e: any) => {
    e.stopPropagation()
    onClose()
  }

  const toggleEdit = () => {
    setEdit((x) => !x)
  }

  const onSubmit = (data: any) => {
    setLoading(true)
    const newFormValue = data
    updateUtenteMaster(loginData!.id, { ...newFormValue })
      .catch((err) => {
        setEdit(false)
        setError(err || "Internal Server Error")
      })
      .finally(() => {
        dispatch(
          setUser({
            ...newFormValue,
            user: newFormValue.user,
            eMail: newFormValue.eMail
          })
        )
        setSuccess("Nome utente e eMail aggiornati con successo")
        setLoading(false)
        setEdit(false)
        onClose()
      })
  }

  const onFormError = (err: any) => {
    setError(
      `I campi Nome Utente e Email sono obbligatori e devono essere validi.`
    )
  }

  return (
    <>
      <BluAlert
        show={!!error}
        handleClose={() => {
          setError("")
        }}
        text={error}
        type="error"
      />
      <BluAlert
        show={!!success}
        handleClose={() => {
          setSuccess("")
        }}
        text={success}
        type="success"
      />

      <BluAlert
        show={logout}
        handleClose={() => {
          setLogout((x) => !x)
        }}
        handleConfirm={() => {
          eraseCookie("AdminToken")
          window.location.reload()
        }}
        text={
          "Sei sicuro di voler uscire da Catalogo Bludata?\nTutte le modifiche non salvate andranno perse."
        }
        type="warning"
      />

      <PasswordDialog
        openPasswordDialog={openPasswordDialog}
        closeDialog={(oldPassword, newPassword) => {
          console.log(oldPassword, newPassword,!oldPassword && !newPassword)
          if(!oldPassword && !newPassword){
            setOpenPasswordDialog(false)
            return
          }
          setModificaPassword(getValues("id"), oldPassword, newPassword)
            .then(() => {
              setSuccess("La password è stata aggiornata con successo")
            })
            .catch((err) => {
              setEdit(false)
              setError(err || "Internal Server Error")
            })
          setOpenPasswordDialog(false)
        }}
      />
      <BluDialog
        open={show}
        zIndex={1}
        maxWidth={"sm"}
        draggable
        PaperProps={
          {
            component: "form",
            onSubmit: methods.handleSubmit(onSubmit, onFormError)
          } as any
        }
        dialogContent={
          user && (
            <Stack direction={"row"}>
              <BluLoader open={loading} />
              <Stack direction="column" flex={1}>
                <Divider sx={{ opacity: 0, mb: "12px!important" }} />
                {edit ? (
                  <FormInputText
                    name={"nomeUtente"}
                    control={control}
                    errors={errors}
                    label="Nome utente"
                    deletetable
                    maxLength={50}
                    onClickAdornment={(arg) => {
                      if (arg === "delete") {
                        setValue("nomeUtente", null)
                      }
                    }}
                  />
                ) : (
                  <Typography variant="h6">
                    <b>Profilo di {user.nomeUtente}</b>
                  </Typography>
                )}

                <Divider sx={{ opacity: 0, mb: "12px!important" }} />
                <BluTitle text="Informazioni" />
                <Typography variant="body1">
                  User: <b>{user.user}</b>
                </Typography>
                <Divider sx={{ opacity: 0, mb: "12px!important" }} />
                <BluTitle text="Contatti" />
                {edit ? (
                  <FormInputText
                    name={"eMail"}
                    control={control}
                    errors={errors}
                    label="Email"
                    deletetable
                    maxLength={50}
                    onClickAdornment={(arg) => {
                      if (arg === "delete") {
                        setValue("eMail", null)
                      }
                    }}
                  />
                ) : (
                  <Typography variant="body1">
                    Email: <b>{user.eMail}</b>
                  </Typography>
                )}
                <Divider sx={{ opacity: 0, mb: "12px!important" }} />
              </Stack>
            </Stack>
          )
        }
        dialogTitle={
          <Stack
            direction={"row"}
            justifyContent={"left"}
            alignItems={"center"}
            spacing={0.5}
          >
            <PersonIcon />
            <BluTitle
              sx={{ color: "var(--reverse-color)" }}
              text={"Informazioni utente"}
            />
          </Stack>
        }
        dialogActions={
          <>
            {edit ? (
              <>
                <Button
                  variant="outlined"
                  startIcon={<LockIcon />}
                  onClick={() => {
                    setOpenPasswordDialog(true)
                  }}
                >
                  Cambia password
                </Button>
                <Box flex={1}></Box>
                <Button
                  onClick={() => {
                    toggleEdit()
                  }}
                  variant="outlined"
                >
                  Annulla
                </Button>
                <Button type="submit">Salva</Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  startIcon={<LogoutIcon />}
                  onClick={() => {
                    setLogout((x) => !x)
                  }}
                >
                  Logout
                </Button>
                <Box flex={1}></Box>
                <Button onClick={toggleEdit} variant="outlined">
                  Modifica
                </Button>
                <Button onClick={handleClose} variant="outlined">
                  Chiudi
                </Button>
              </>
            )}
          </>
        }
      />
    </>
  )
}

export default UserDialog
