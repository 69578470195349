import { Stack } from "@mui/system"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { Box, Button, Card, Typography } from "@mui/material"
import { memo, useDeferredValue, useEffect, useState } from "react"
import axios from "axios"
import { Add, Edit, Delete, FileDownload, Article } from "@mui/icons-material"
import { BluAlert } from "@bludata/components"
import { DataGridPremium, GridSelectionModel } from "@mui/x-data-grid-premium"
import columns from "./columns"
import BludataSearchField from "../../UI/BludataSearchField"
import deleteUtente from "../../api/utenteOttico/deleteUtente"
import * as XLSX from "xlsx"
import fetchListaUtenti from "../../api/global/fetchListaUtenti"
import BludataDefaultMainHeader from "../../UI/BludataDefaultMainHeader"
import BludataLoaderFullscreen from "../../UI/BludataLoaderFullscreen"
import Utente from "../../interfaces/utente"
import CentroOtticoForm from "./centroOtticoForm"
import FolderOffIcon from "@mui/icons-material/FolderOff"

export const CentroOttico = memo(() => {
  const { accessToken, currentTreeSelection } = useSelector(
    (state: RootState) => state.root
  )
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const [listaUtenti, setListaUtenti] = useState<{
    totaleUtenti: number
    totalePagine: number
    paginaCorrente: number
    dimensionePagine: number
    utenteOttico: Utente[]
  }>()
  const [isEdit, setIsEdit] = useState<"view" | "new" | "edit" | null>(null)
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const [page, setPage] = useState(0)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const pageSize = 50
  const [query, setQuery] = useState("")
  const deferredQuery = useDeferredValue(query)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  if (accessToken) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
  } else {
    axios.defaults.headers.common["Authorization"] = ""
  }

  const reload = () => {
    setLoading(true)
    fetchListaUtenti(
      currentTreeSelection!.id,
      page + 1,
      pageSize,
      deferredQuery
    )
      .then((response) => {
        setListaUtenti(response)
        setLoading(false)
        setIsDataLoaded(true)
      })
      .catch((err) => {
        setLoading(false)
        setIsDataLoaded(true)
        setError(err || "Internal Server Error")
      })
  }

  useEffect(() => {
    if (currentTreeSelection){
      reload()
    }

    const timer = setTimeout(() => {
      setLoading(false)
      setIsDataLoaded(true);
    }, 1000);

    return () => clearTimeout(timer);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTreeSelection, page, deferredQuery])

  return (
    <Box
      flex="1"
      flexDirection="column"
      style={{ width: "100%", height: "100%" }}
    >
      {!!isEdit && !loading && (
        <CentroOtticoForm
          isEdit={isEdit}
          id={selectionModel[0]}
          close={() => {
            setIsEdit(null)
            reload()
          }}
        />
      )}
      {loading && <BludataLoaderFullscreen open />}
      <BluAlert
        type="warning"
        show={deleteConfirm}
        handleClose={() => {
          setDeleteConfirm(false)
        }}
        handleConfirm={() => {
          deleteUtente(Number(selectionModel[0]))
            .catch((err) => {
              setError(err || "Internal Server Error")
              setDeleteConfirm(false)
              reload()
            })
            .finally(() => {
              setDeleteConfirm(false)
              reload()
            })
        }}
        text={"Sei sicuro di voler eliminare questo utente?"}
      />
      <BluAlert
        type="error"
        show={!!error}
        handleClose={() => {
          setError("")
        }}
        text={error}
      />
      <Box
        sx={{
          flex: 1,
          height: "100%",
          backgroundColor: "var(--hovered-background-color)"
        }}
      >
        {!isDataLoaded ? (
          <BludataLoaderFullscreen open={true} /> // Sostituisci con il tuo componente di caricamento
        ) : (
          <>
            {isDataLoaded && currentTreeSelection ? (
              <Stack flexDirection="column" flex={1} sx={{ height: "100%" }}>
                <BludataDefaultMainHeader
                  showReload={true}
                  text={`Centro ottico / ${currentTreeSelection!.label}`}
                  onRefreshTap={reload}
                />
                <Card
                  variant="outlined"
                  sx={{ height: "100%", p: 1, m: 0.5, mt: 0 }}
                >
                  <Stack direction="column" spacing={1} sx={{ height: "100%" }}>
                    <Typography>
                      In questa sezione è possibile configurare l'elenco degli
                      utenti abilitati al download.
                    </Typography>
                    <BludataSearchField
                      label="Ricerca"
                      ricerca={query}
                      onRicercaChange={setQuery}
                    />
                    <DataGridPremium
                      columns={columns}
                      sx={{
                        "& .MuiDataGrid-cell": {
                          outline: "none!important"
                        }
                      }}
                      rows={!!listaUtenti ? listaUtenti.utenteOttico : []}
                      onSelectionModelChange={(ids) => {
                        if (selectionModel[0] === ids[0]) {
                          setSelectionModel([])
                        } else {
                          setSelectionModel(ids)
                        }
                      }}
                      selectionModel={selectionModel}
                      hideFooterSelectedRowCount
                      page={page}
                      pageSize={pageSize}
                      onPageChange={(pageNumber) => setPage(pageNumber)}
                      pagination
                      rowsPerPageOptions={[pageSize]}
                      paginationMode="server"
                      rowCount={!!listaUtenti ? listaUtenti.totaleUtenti : 0}
                    />
                    <Stack
                      direction={"row"}
                      justifyContent={"end"}
                      alignItems={"center"}
                      spacing={1}
                    >
                      <Button
                        startIcon={<Article />}
                        disabled={!selectionModel.length}
                        variant="outlined"
                        onClick={() => {
                          setIsEdit("view")
                        }}
                      >
                        Scheda
                      </Button>
                      <Box width={10} />
                      <Button
                        startIcon={<Add />}
                        onClick={() => {
                          setIsEdit("new")
                        }}
                      >
                        Nuovo
                      </Button>
                      <Button
                        startIcon={<Edit />}
                        disabled={!selectionModel.length}
                        variant="outlined"
                        onClick={() => {
                          setIsEdit("edit")
                        }}
                      >
                        Modifica
                      </Button>
                      <Button
                        startIcon={<Delete />}
                        disabled={!selectionModel.length}
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setDeleteConfirm(true)
                        }}
                      >
                        Elimina
                      </Button>
                      <Box width={10} />
                      <Button
                        startIcon={<FileDownload />}
                        disabled={!selectionModel.length}
                        variant="outlined"
                        onClick={() => {
                          const visibleColumns = columns.filter(
                            (column) => !column.hide
                          )

                          const data = listaUtenti!.utenteOttico.map(
                            (row: any) =>
                              visibleColumns.map((column) => {
                                const value = row[column.field]
                                return value !== undefined ? value : ""
                              })
                          )

                          const ws = XLSX.utils.aoa_to_sheet([
                            visibleColumns.map((column) => column.headerName),
                            ...data
                          ])

                          const wb = XLSX.utils.book_new()
                          XLSX.utils.book_append_sheet(
                            wb,
                            ws,
                            `Centri ottici.xlsx`
                          )
                          XLSX.writeFile(wb, `Centri ottici.xlsx`)
                        }}
                      >
                        Esporta in Excel
                      </Button>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            ) : (
              <Stack
                flexDirection="column"
                flex={1}
                sx={{ height: "100%" }}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <Card
                  variant="outlined"
                  sx={{ width: "100%", height: "100%", p: 1, m: 0.5 }}
                >
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{ height: "100%" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <FolderOffIcon sx={{ fontSize: "10rem" }} color="primary" />
                    <Typography variant="h5">
                      Non hai nessun catalogo associato
                    </Typography>
                    <Typography variant="h6">
                      <b>Contattare Bludata Informatica</b>
                    </Typography>
                  </Stack>
                </Card>
              </Stack>
            )}
          </>
        )}
      </Box>
    </Box>
  )
})
